var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"buttons justify-content-start mt-2"},[_c('div',{staticClass:"button primary sm",on:{"click":function () {
            if(_vm.createNewIncentive) {
              _vm.createNewIncentive = false
              _vm.newIncentive = {
                incentive: '',
                image: '',
                image_extension: '',
                description: '',
                national: 1,
                link: '',
              }
            } else {
              _vm.createNewIncentive = true

            }
          }}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Adicionar apoio ")],1)])]),(_vm.createNewIncentive)?_c('b-col',[_c('b-form',{staticStyle:{"text-align":"left"},on:{"submit":_vm.newIncentiveSave}},[_c('b-form-group',{attrs:{"id":"incentive","label":"Apoio *","label-for":"incentive"}},[_c('b-form-input',{attrs:{"id":"incentive","type":"text","placeholder":"Nome do apoio","required":""},model:{value:(_vm.newIncentive.incentive),callback:function ($$v) {_vm.$set(_vm.newIncentive, "incentive", $$v)},expression:"newIncentive.incentive"}})],1),_c('b-form-group',{attrs:{"label":"Logo","label-for":"image"}},[_c('b-form-file',{attrs:{"id":"image","state":Boolean(_vm.newIncentive.image),"name":("" + (_vm.newIncentive.incentive)),"placeholder":_vm.newIncentive.image ?
              ((_vm.newIncentive.incentive) + "." + (_vm.newIncentive.image_extension)) :
              'Selecione um ficheiro ou mova-o para aqui...',"drop-placeholder":"Largue o ficheiro aqui...","accept":".jpg, .png, .svg"},on:{"input":function (value) {
              _vm.uploadNewIncentiveImage(value)
            }}})],1),_c('b-form-group',{attrs:{"id":"description","label":"Descrição *","label-for":"description"}},[_c('b-form-input',{attrs:{"id":"description","type":"text","placeholder":"Descrição","required":""},model:{value:(_vm.newIncentive.description),callback:function ($$v) {_vm.$set(_vm.newIncentive, "description", $$v)},expression:"newIncentive.description"}})],1),_c('b-form-group',{attrs:{"id":"link","label":"Website *","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","type":"text","placeholder":"Website do apoio","required":""},model:{value:(_vm.newIncentive.link),callback:function ($$v) {_vm.$set(_vm.newIncentive, "link", $$v)},expression:"newIncentive.link"}})],1),_c('b-form-group',{attrs:{"label":"Individual radios"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"1"},model:{value:(_vm.newIncentive.national),callback:function ($$v) {_vm.$set(_vm.newIncentive, "national", $$v)},expression:"newIncentive.national"}},[_vm._v(" Nacional ")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"0"},model:{value:(_vm.newIncentive.national),callback:function ($$v) {_vm.$set(_vm.newIncentive, "national", $$v)},expression:"newIncentive.national"}},[_vm._v(" Internacional ")])]}}],null,false,3258372980)}),_c('div',[_vm._v("* Campo de preenchimento obrigatório")]),_c('div',{staticClass:"buttons justify-content-start"},[_c('button',{staticClass:"button primary sm",attrs:{"type":"submit","variant":"primary"}},[_c('b-icon',{attrs:{"icon":"cloud-upload"}}),_vm._v(" Guardar ")],1),_c('button',{staticClass:"button warning sm",attrs:{"variant":"danger"},on:{"click":function () {
              _vm.createNewIncentive = false
              _vm.newIncentive = {
                incentive: '',
                image: '',
                image_extension: '',
                description: '',
                national: 1,
                link: '',
              }
            }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1)])],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('b-input-group',{staticClass:"mb-3",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('b-icon',{attrs:{"icon":"search","scale":"0.8"}})],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Pesquisar..."},model:{value:(_vm.filterIncentives),callback:function ($$v) {_vm.filterIncentives=$$v},expression:"filterIncentives"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filterIncentives},on:{"click":function($event){_vm.filterIncentives = ''}}},[_vm._v(" Limpar ")])],1)],1),(_vm.incentives)?_c('b-table',{attrs:{"id":"incentives","items":_vm.incentives,"per-page":_vm.perPageIncentives,"current-page":_vm.currentPageIncentives,"fields":_vm.fieldsIncentives,"filter":_vm.filterIncentives,"filter-included-fields":_vm.filterOnIncentives,"borderless":"","hover":"","small":""},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
            var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('b-form-checkbox',{attrs:{"checked":rowSelected,"value":"true"}})]:[_c('b-form-checkbox',{attrs:{"checked":rowSelected}})]]}},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"buttons m-0 p-0 justify-content-start buttons-group"},[_c('div',{staticClass:"button primary xs",on:{"click":row.toggleDetails}},[_c('b-icon',{attrs:{"scale":"1.2","icon":row.detailsShowing ? 'eye-slash' : 'eye'}})],1)])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('div',{staticStyle:{"align-items":"flex-start","display":"flex","flex-direction":"column"}},[(!_vm.editIncentive || _vm.editIncentiveId !== row.item['id'] )?_c('div',{staticStyle:{"align-items":"flex-start","display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"order":"1"}},[_c('b',[_vm._v("Apoio:")]),_vm._v(" "+_vm._s(row.item['incentive'])+" ")]),(row.item['image'])?_c('div',{staticClass:"mt-5 mb-5",staticStyle:{"order":"2"}},[_c('img',{attrs:{"height":"100px","src":("" + (row.item['image']))}})]):_vm._e(),_c('div',{staticStyle:{"order":"3"}},[_c('b',[_vm._v("Descrição:")]),_vm._v(" "+_vm._s(row.item['description'])+" ")]),_c('div',{staticClass:"text-left",staticStyle:{"order":"4"}},[_c('b',[_vm._v("Nacional:")]),_vm._v(" "+_vm._s(row.item['national'] == 1 ? 'Sim' : 'Não')+" ")]),_c('div',{staticClass:"text-left",staticStyle:{"order":"6"}},[_c('b',[_vm._v("Website:")]),_c('a',{attrs:{"href":row.item['link'],"target":"_blank"}},[_vm._v(_vm._s(row.item['link']))])]),( row.item['created_at'])?_c('div',{staticStyle:{"order":"8"}},[_c('b',[_vm._v("Criado em:")]),_vm._v(" "+_vm._s(row.item['created_at'])+" ")]):_vm._e(),_c('div',{staticClass:"buttons",staticStyle:{"order":"9"}},[_c('div',{staticClass:"button primary sm",on:{"click":function($event){return _vm.setEditIncentive(row.item['id'])}}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v(" Editar ")],1),(row.item.active)?_c('div',{staticClass:"button danger sm",on:{"click":function () {
                      _vm.deleteIncentiveId = row.item['id'];
                      _vm.$bvModal.show('deleteIncentive')
                    }}},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Desativar ")],1):_c('div',{staticClass:"button success sm",on:{"click":function () {
                      _vm.deleteIncentiveId = row.item['id'];
                      _vm.$bvModal.show('activeIncentive')
                    }}},[_c('b-icon',{attrs:{"icon":"check"}}),_vm._v(" Ativar ")],1)])]):_c('div',{staticStyle:{"width":"100%"}},[(_vm.incentive && _vm.editIncentive && _vm.editIncentiveId === row.item['id'])?_c('b-form',{staticStyle:{"text-align":"left"},on:{"submit":_vm.incentiveSave}},[_c('b-form-group',{attrs:{"id":"incentive","label":"Apoio *","label-for":"incentive"}},[_c('b-form-input',{attrs:{"id":"incentive","type":"text","placeholder":"Nome do apoio","required":""},model:{value:(_vm.incentive.incentive),callback:function ($$v) {_vm.$set(_vm.incentive, "incentive", $$v)},expression:"incentive.incentive"}})],1),_c('b-form-group',{attrs:{"label":"Logo","label-for":"image"}},[_c('b-form-file',{attrs:{"id":"image","state":Boolean(_vm.incentive.image),"name":("" + (_vm.incentive.incentive)),"placeholder":_vm.incentive.image ?
                        ((_vm.incentive.incentive) + "." + (_vm.incentive.image_extension)) :
                        'Selecione um ficheiro ou mova-o para aqui...',"drop-placeholder":"Largue o ficheiro aqui...","accept":".jpg, .png, .svg"},on:{"input":function (value) {
                        _vm.uploadIncentiveImage(value)
                      }}})],1),_c('b-form-group',{attrs:{"id":"description","label":"Descrição *","label-for":"description"}},[_c('b-form-input',{attrs:{"id":"description","type":"text","placeholder":"Descrição","required":""},model:{value:(_vm.incentive.description),callback:function ($$v) {_vm.$set(_vm.incentive, "description", $$v)},expression:"incentive.description"}})],1),_c('b-form-group',{attrs:{"id":"link","label":"Website *","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","type":"text","placeholder":"Website do apoio","required":""},model:{value:(_vm.incentive.link),callback:function ($$v) {_vm.$set(_vm.incentive, "link", $$v)},expression:"incentive.link"}})],1),_c('b-form-group',{attrs:{"label":"Individual radios"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"1"},model:{value:(_vm.incentive.national),callback:function ($$v) {_vm.$set(_vm.incentive, "national", $$v)},expression:"incentive.national"}},[_vm._v(" Nacional ")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"0"},model:{value:(_vm.incentive.national),callback:function ($$v) {_vm.$set(_vm.incentive, "national", $$v)},expression:"incentive.national"}},[_vm._v(" Internacional ")])]}}],null,true)}),_c('div',[_vm._v("* Campo de preenchimento obrigatório")]),_c('div',{staticClass:"buttons justify-content-start"},[_c('button',{staticClass:"button primary sm",attrs:{"type":"submit","variant":"primary"}},[_c('b-icon',{attrs:{"icon":"cloud-upload"}}),_vm._v(" Guardar ")],1),_c('button',{staticClass:"button warning sm",attrs:{"variant":"danger"},on:{"click":function () {
                        _vm.editIncentive = false
                        _vm.editIncentiveId = null
                      }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1)])],1):_vm._e()],1)])])]}}],null,false,2595259134)}):_vm._e(),_c('b-pagination',{attrs:{"total-rows":_vm.rowsIncentives,"per-page":_vm.perPageIncentives,"aria-controls":"incentives"},model:{value:(_vm.currentPageIncentives),callback:function ($$v) {_vm.currentPageIncentives=$$v},expression:"currentPageIncentives"}})],1)],1),_c('b-modal',{attrs:{"id":"deleteIncentive","header-class":"border-bottom-0","footer-class":"border-top-0","hide-header":"","centered":"","title":"desativar apoio","hide-footer":"","size":"lg"}},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Desativar apoio ")]),_c('div',{staticClass:"text-dark text-center"},[_vm._v(" Tem a certeza que pretende desativar este apoio? ")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button secondary sm",attrs:{"type":"submit"},on:{"click":function () {
            _vm.deleteIncentiveId = null
            _vm.$bvModal.hide('deleteIncentive');
          }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"button danger sm",attrs:{"type":"submit"},on:{"click":_vm.deleteIncentive}},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Desativar ")],1)])])]),_c('b-modal',{attrs:{"id":"activeIncentive","header-class":"border-bottom-0","footer-class":"border-top-0","hide-header":"","centered":"","title":"ativar apoio","hide-footer":"","size":"lg"}},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Ativar apoio ")]),_c('div',{staticClass:"text-dark text-center"},[_vm._v(" Tem a certeza que pretende ativar este apoio? ")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button secondary sm",attrs:{"type":"submit"},on:{"click":function () {
            _vm.deleteIncentiveId = null
            _vm.$bvModal.hide('activeIncentive');
          }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"button success sm",attrs:{"type":"submit"},on:{"click":_vm.activeIncentive}},[_c('b-icon',{attrs:{"icon":"check"}}),_vm._v(" Ativar ")],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }