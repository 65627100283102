<template>
  <b-overlay
    :show="loading"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <div
      class="dahsboard-admin-container"
    >
      <b-row
        class="align-items-center justify-content-space-between"
      >
        <b-col
          cols="12"
        >
          <b-row>
            <b-col>
              <b-tabs
                v-model="tabIndex"
                active-nav-item-class="text-primary"
              >
                <b-tab
                  title="Site"
                  lazy
                  :title-link-class="linkClass(0)"
                >
                  <b-tabs
                    pills
                    card
                    class="transparent"
                  >
                    <b-tab
                      lazy
                      title="Testemunhos"
                    >
                      <testimonials-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      lazy
                      title="Videos"
                    >
                      <videos-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      lazy
                      title="Apoios"
                    >
                      <incentives-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      lazy
                      title="Resultados"
                    >
                      <results-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      lazy
                      title="Roadmaps"
                    >
                      <roadmaps-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      lazy
                      title="Recursos"
                    >
                      <resources-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                  </b-tabs>
                </b-tab>
                <b-tab
                  title="Plataforma"
                  lazy
                  :title-link-class="linkClass(1)"
                >
                  <b-tabs
                    pills
                    card
                    class="transparent"
                  >
                    <b-tab
                      title="Definições gerais"
                      lazy
                    >
                      <general-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      title="Documentos"
                      lazy
                    >
                      <documents-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      title="Facilitadores"
                      lazy
                    >
                      <facilitators-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      title="Manifestação de interesse"
                    >
                      <pre-registers-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      title="Questionários"
                    >
                      <quizzes-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      title="FAQs"
                    >
                      <faqs-tab
                        :set-loading="setLoading"
                      />
                    </b-tab>
                    <b-tab
                      v-if="$store.getters.user.email === 'fabio.henriques@sinmetro.pt'"
                      title="Erros/Bugs"
                    >
                      <errors-tab
                        v-if="$store.getters.user.email === 'fabio.henriques@sinmetro.pt'"
                        :set-loading="setLoading"
                      />
                    </b-tab>
                  </b-tabs>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import GeneralTab from './DashboardAdminTabs/GeneralTab.vue';
import DocumentsTab from './DashboardAdminTabs/DocumentsTab.vue';
import FacilitatorsTab from './DashboardAdminTabs/FacilitatorsTab.vue';
import TestimonialsTab from './DashboardAdminTabs/TestimonialsTab.vue';
import IncentivesTab from './DashboardAdminTabs/IncentivesTab.vue';
import ResultsTab from './DashboardAdminTabs/ResultsTab.vue';
import ResourcesTab from './DashboardAdminTabs/ResourcesTab.vue';
import RoadmapsTab from './DashboardAdminTabs/RoadmapsTab.vue';
import PreRegistersTab from './DashboardAdminTabs/PreRegistersTab.vue';
import QuizzesTab from './DashboardAdminTabs/QuizzesTab.vue';
import VideosTab from './DashboardAdminTabs/VideosTab.vue';
import FaqsTab from './DashboardAdminTabs/FaqsTab.vue';
import ErrorsTab from './DashboardAdminTabs/ErrorsTab.vue';

export default {
  name: 'DashboardAdmin',
  components: {
    GeneralTab,
    DocumentsTab,
    FacilitatorsTab,
    TestimonialsTab,
    IncentivesTab,
    ResultsTab,
    ResourcesTab,
    RoadmapsTab,
    PreRegistersTab,
    QuizzesTab,
    VideosTab,
    FaqsTab,
    ErrorsTab,
  },
  props: {
    setState: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
    };
  },

  async created() {
    if (this.$store.getters.user.permission_id !== 1) {
      this.setState(2);
    }
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['text-primary'];
      }
      return ['text-secondary'];
    },
    setLoading(value) {
      this.loading = value;
    },
  },
};
</script>
