<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="buttons justify-content-start mt-2">
          <div
            class="button primary sm"
            @click="recalculate"
          >
            <b-icon icon="calculator" /> Recalcular classificações
          </div>

          <div
            class="button danger sm"
            @click="reset"
          >
            <b-icon icon="arrow-clockwise" /> Reset
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  get, post, put, remove,
} from '../../../services/api';
import { generateChartSite } from '../../../services/common/generate-chart-site';

export default {
  name: 'ErrorsTab',
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      quizzes: [],
    };
  },
  async created() {
    this.setLoading(true);
    this.setLoading(false);
  },
  methods: {
    async asyncForEach(array, callback) {
      /* eslint-disable no-await-in-loop */
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array);
      }
      /* eslint-enable no-await-in-loop */
    },
    async reset() {
      await post('reset-updated-quizzes');
    },
    async recalculate() {
      await post('recalculate/').then(async (response) => {
        const { quizzes } = response.data;

        await this.asyncForEach(quizzes, async (quiz) => {
          const svg = generateChartSite(
            quiz.themes,
            quiz.dimensions,
            quiz.global,
            quiz.future,
            quiz.kos,
          ).innerHTML.replaceAll('textpath', 'textPath').replaceAll('startoffset', 'startOffset');
          await put(`/quiz/${quiz.id}`, {
            svg,
            recalculate: true,
            status: 'submitted',
          }).then(async () => {
            await get(`/score/pdf/silence/${quiz.id}`, {}).catch(async () => {
              await put(`/quiz/${quiz.id}`, {
                recalculate: true,
                status: 'submitted',
                updated: 0,
              });
            });
          }).catch(async () => {
            await put(`/quiz/${quiz.id}`, {
              recalculate: true,
              status: 'submitted',
              updated: 0,
            });
          });
        });
      });
    },
  },
};
</script>
