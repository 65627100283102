<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="buttons justify-content-start mt-2">
          <div
            class="button primary sm"

            @click="() => {
              if(createNewIncentive) {
                createNewIncentive = false
                newIncentive = {
                  incentive: '',
                  image: '',
                  image_extension: '',
                  description: '',
                  national: 1,
                  link: '',
                }
              } else {
                createNewIncentive = true

              }
            }"
          >
            <b-icon icon="plus" /> Adicionar apoio
          </div>
        </div>
      </b-col>
      <b-col v-if="createNewIncentive">
        <b-form
          style="text-align: left;"
          @submit="newIncentiveSave"
        >
          <b-form-group
            id="incentive"
            label="Apoio *"
            label-for="incentive"
          >
            <b-form-input
              id="incentive"
              v-model="newIncentive.incentive"
              type="text"
              placeholder="Nome do apoio"
              required
            />
          </b-form-group>
          <b-form-group
            label="Logo"
            label-for="image"
          >
            <b-form-file
              id="image"
              :state="Boolean(newIncentive.image)"
              :name="`${newIncentive.incentive}`"
              :placeholder="newIncentive.image ?
                `${newIncentive.incentive}.${newIncentive.image_extension}` :
                'Selecione um ficheiro ou mova-o para aqui...'"
              drop-placeholder="Largue o ficheiro aqui..."
              accept=".jpg, .png, .svg"
              @input="(value) => {
                uploadNewIncentiveImage(value)
              }"
            />
          </b-form-group>

          <b-form-group
            id="description"
            label="Descrição *"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="newIncentive.description"
              type="text"
              placeholder="Descrição"
              required
            />
          </b-form-group>

          <b-form-group
            id="link"
            label="Website *"
            label-for="link"
          >
            <b-form-input
              id="link"
              v-model="newIncentive.link"
              type="text"
              placeholder="Website do apoio"
              required
            />
          </b-form-group>

          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Individual radios"
          >
            <b-form-radio
              v-model="newIncentive.national"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="1"
            >
              Nacional
            </b-form-radio>
            <b-form-radio
              v-model="newIncentive.national"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="0"
            >
              Internacional
            </b-form-radio>
          </b-form-group>

          <div>* Campo de preenchimento obrigatório</div>
          <div class="buttons justify-content-start">
            <button
              class="button primary sm"
              type="submit"
              variant="primary"
            >
              <b-icon
                icon="cloud-upload"
              /> Guardar
            </button>
            <button
              class="button warning sm"
              variant="danger"
              @click="() => {
                createNewIncentive = false
                newIncentive = {
                  incentive: '',
                  image: '',
                  image_extension: '',
                  description: '',
                  national: 1,
                  link: '',
                }
              }"
            >
              <b-icon
                icon="x"
              /> Cancelar
            </button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterIncentives"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterIncentives"
              @click="filterIncentives = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          v-if="incentives"
          id="incentives"
          :items="incentives"
          :per-page="perPageIncentives"
          :current-page="currentPageIncentives"
          :fields="fieldsIncentives"
          :filter="filterIncentives"
          :filter-included-fields="filterOnIncentives"
          borderless
          hover
          small
        >
          <template
            #cell(selected)="{ rowSelected }"
          >
            <template
              v-if="rowSelected"
            >
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="rowSelected"
              />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <div style=" align-items: flex-start; display: flex; flex-direction: column;">
                <div
                  v-if="!editIncentive || editIncentiveId !== row.item['id'] "
                  style=" align-items: flex-start; display: flex; flex-direction: column;"
                >
                  <div
                    style="order: 1;"
                  >
                    <b>Apoio:</b> {{ row.item['incentive'] }}
                  </div>
                  <div
                    v-if="row.item['image']"
                    style="order: 2;"
                    class="mt-5 mb-5"
                  >
                    <img
                      height="100px"
                      :src="`${row.item['image']}`"
                    >
                  </div>
                  <div
                    style="order: 3;"
                  >
                    <b>Descrição:</b> {{ row.item['description'] }}
                  </div>
                  <div
                    style="order: 4;"
                    class="text-left"
                  >
                    <b>Nacional:</b>
                    {{ row.item['national'] == 1 ? 'Sim' : 'Não' }}
                  </div>
                  <div
                    style="order: 6;"
                    class="text-left"
                  >
                    <b>Website:</b>
                    <a
                      :href="row.item['link']"
                      target="_blank"
                    >{{ row.item['link'] }}</a>
                  </div>
                  <div
                    v-if=" row.item['created_at']"
                    style="order: 8;"
                  >
                    <b>Criado em:</b> {{ row.item['created_at'] }}
                  </div>
                  <div
                    class="buttons"
                    style="order: 9;"
                  >
                    <div
                      class="button primary sm"
                      @click="setEditIncentive(row.item['id'])"
                    >
                      <b-icon icon="pencil" /> Editar
                    </div>
                    <div
                      v-if="row.item.active"
                      class="button danger sm"
                      @click="() => {
                        deleteIncentiveId = row.item['id'];
                        $bvModal.show('deleteIncentive')
                      }"
                    >
                      <b-icon icon="trash" /> Desativar
                    </div>
                    <div
                      v-else
                      class="button success sm"
                      @click="() => {
                        deleteIncentiveId = row.item['id'];
                        $bvModal.show('activeIncentive')
                      }"
                    >
                      <b-icon icon="check" /> Ativar
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="width: 100%;"
                >
                  <b-form
                    v-if="incentive && editIncentive && editIncentiveId === row.item['id']"
                    style="text-align: left;"
                    @submit="incentiveSave"
                  >
                    <b-form-group
                      id="incentive"
                      label="Apoio *"
                      label-for="incentive"
                    >
                      <b-form-input
                        id="incentive"
                        v-model="incentive.incentive"
                        type="text"
                        placeholder="Nome do apoio"
                        required
                      />
                    </b-form-group>
                    <b-form-group
                      label="Logo"
                      label-for="image"
                    >
                      <b-form-file
                        id="image"
                        :state="Boolean(incentive.image)"
                        :name="`${incentive.incentive}`"
                        :placeholder="incentive.image ?
                          `${incentive.incentive}.${incentive.image_extension}` :
                          'Selecione um ficheiro ou mova-o para aqui...'"
                        drop-placeholder="Largue o ficheiro aqui..."
                        accept=".jpg, .png, .svg"
                        @input="(value) => {
                          uploadIncentiveImage(value)
                        }"
                      />
                    </b-form-group>

                    <b-form-group
                      id="description"
                      label="Descrição *"
                      label-for="description"
                    >
                      <b-form-input
                        id="description"
                        v-model="incentive.description"
                        type="text"
                        placeholder="Descrição"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      id="link"
                      label="Website *"
                      label-for="link"
                    >
                      <b-form-input
                        id="link"
                        v-model="incentive.link"
                        type="text"
                        placeholder="Website do apoio"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      v-slot="{ ariaDescribedby }"
                      label="Individual radios"
                    >
                      <b-form-radio
                        v-model="incentive.national"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        value="1"
                      >
                        Nacional
                      </b-form-radio>
                      <b-form-radio
                        v-model="incentive.national"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        value="0"
                      >
                        Internacional
                      </b-form-radio>
                    </b-form-group>
                    <div>* Campo de preenchimento obrigatório</div>
                    <div class="buttons justify-content-start">
                      <button
                        class="button primary sm"
                        type="submit"
                        variant="primary"
                      >
                        <b-icon icon="cloud-upload" /> Guardar
                      </button>
                      <button
                        class="button warning sm"
                        variant="danger"
                        @click="() => {
                          editIncentive = false
                          editIncentiveId = null
                        }"
                      >
                        <b-icon
                          icon="x"
                        /> Cancelar
                      </button>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageIncentives"
          :total-rows="rowsIncentives"
          :per-page="perPageIncentives"
          aria-controls="incentives"
        />
      </b-col>
    </b-row>
    <b-modal
      id="deleteIncentive"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="desativar apoio"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Desativar apoio
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende desativar este apoio?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteIncentiveId = null
              $bvModal.hide('deleteIncentive');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button danger sm"
            type="submit"
            @click="deleteIncentive"
          >
            <b-icon icon="trash" />
            Desativar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="activeIncentive"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="ativar apoio"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Ativar apoio
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende ativar este apoio?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteIncentiveId = null
              $bvModal.hide('activeIncentive');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button success sm"
            type="submit"
            @click="activeIncentive"
          >
            <b-icon icon="check" />
            Ativar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  get, post, put, remove,
} from '../../../services/api';

export default {
  name: 'IncentivesTab',
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      currentPageIncentives: 1,
      filterOnIncentives: ['incentive', 'description', 'link'],
      perPageIncentives: 10,
      incentives: null,
      deleteIncentiveId: null,
      filterIncentives: null,
      fieldsIncentives: [
        {
          key: 'incentive', sortable: true, label: 'Apoio', thStyle: { width: '30%' },
        },
        {
          key: 'description', sortable: true, label: 'Descrição', thStyle: { width: '50%' },
        },
        {
          key: 'active',
          sortable: true,
          label: 'Ativo',
          formatter: (value) => (value ? 'Sim' : 'Não'),
          thStyle: { width: '10%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      createNewIncentive: false,
      editIncentive: false,
      editIncentiveId: null,
      newIncentive: {
        incentive: '',
        image: '',
        image_extension: '',
        description: '',
        national: 1,
        link: '',
      },
    };
  },
  computed: {
    rowsIncentives() {
      if (this.incentives) {
        return this.incentives.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getIncentives();
    this.setLoading(false);
  },
  methods: {
    setEditIncentive(id) {
      this.incentive = this.incentives.find((el) => el.id === id);

      this.editIncentiveId = id;
      this.editIncentive = true;
    },
    async getIncentives() {
      await get('incentives/', { all: true }).then((response) => {
        this.incentives = response.data;
      });
    },

    uploadNewIncentiveImage(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.newIncentive.image_extension = value.name.split('.')[value.name.split('.').length - 1];
        this.newIncentive.image = reader.result;
      };
    },
    uploadIncentiveImage(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.incentive.image_extension = value.name.split('.')[value.name.split('.').length - 1];
        this.incentive.image = reader.result;
      };
    },
    async activeIncentive() {
      await put(`incentives/${this.deleteIncentiveId}`, {
        active: 1,
      });
      this.deleteIncentiveId = null;
      this.getIncentives();
      this.$bvModal.hide('activeIncentive');
    },
    async deleteIncentive() {
      await put(`incentives/${this.deleteIncentiveId}`, {
        active: 0,
      });
      this.deleteIncentiveId = null;
      this.getIncentives();
      this.$bvModal.hide('deleteIncentive');
    },
    async incentiveSave(event) {
      event.preventDefault();

      await put(`incentives/${this.editIncentiveId}`, this.incentive);
      this.editIncentive = false;
      this.editIncentiveId = null;
      this.getIncentives();
    },
    async newIncentiveSave(event) {
      event.preventDefault();

      await post('incentives/', this.newIncentive);
      this.createNewIncentive = false;
      this.newIncentive = null;
      this.getIncentives();
    },
  },
};
</script>
