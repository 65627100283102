<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="buttons justify-content-start mt-2">
          <div
            class="button primary sm"

            @click="() => {
              if(createNewFaq) {
                createNewFaq = false
                newFaq = {
                  question: '',
                  answer: '',
                  level: '',
                }
              } else {
                createNewFaq = true
              }
            }"
          >
            <b-icon icon="plus" /> Adicionar FAQ
          </div>
        </div>
      </b-col>
      <b-col v-if="createNewFaq">
        <b-form
          style="text-align: left;"
          @submit="newFaqSave"
        >
          <b-form-group
            id="question"
            label="Pergunta *"
            label-for="question"
          >
            <b-form-textarea
              id="question"
              v-model="newFaq.question"
              placeholder="Pergunta"
              required
              style="min-height: 200px;"
            />
          </b-form-group>

          <b-form-group
            id="answer"
            label="Resposta *"
            label-for="answer"
          >
            <vue-editor v-model="newFaq.answer" />
            <!--  <b-form-textarea
              id="answer"
              v-model="newFaq.answer"
              placeholder="Resposta"
              required
              style="min-height: 200px;"
            /> -->
          </b-form-group>
          <b-form-group
            label="Grupo de permissões de acesso:"
          >
            <b-form-radio-group
              id="radio-group-2"
              v-model="newFaq.level"
              required
              name="radio-sub-component"
            >
              <b-form-radio value="1">
                Empresa
              </b-form-radio>
              <b-form-radio value="2">
                Facilitador
              </b-form-radio>
              <b-form-radio value="3">
                Todos
              </b-form-radio>
              <b-form-radio value="4">
                Site
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div>* Campo de preenchimento obrigatório</div>
          <div class="buttons justify-content-start">
            <button
              class="button primary sm"
              type="submit"
              variant="primary"
            >
              <b-icon
                icon="cloud-upload"
              /> Guardar
            </button>
            <button
              class="button warning sm"
              variant="danger"
              @click="() => {
                createNewFaq = false
                newFaq = {
                  question: '',
                  answer: '',
                  level: ''
                }
              }"
            >
              <b-icon
                icon="x"
              />
              Cancelar
            </button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <b-form-input
            id="filter-input"
            v-model="filterFaqs"
            type="search"
            placeholder="Procurar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterFaqs"
              @click="filterFaqs = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          v-if="faqs"
          id="faqs"
          :items="faqs"
          :per-page="perPageFaqs"
          :current-page="currentPageFaqs"
          :fields="fieldsFaqs"
          :filter="filterFaqs"
          :filter-included-fields="filterOnFaqs"
          borderless
          hover
          small
        >
          <template
            #cell(selected)="{ rowSelected }"
          >
            <template
              v-if="rowSelected"
            >
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="rowSelected"
              />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>

          <template #cell(answer)="row">
            <div class="text-overflow ellipsis">
              <span
                class="text-overflow-concat"
              >
                <span
                  v-for="(desc, k) in row.value.split('\n')"
                  :key="k"
                >

                  <div v-html="desc" />
                </span>
              </span>
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <div style=" align-items: flex-start; display: flex; flex-direction: column;">
                <div
                  v-if="!editFaq || editFaqId !== row.item['id'] "
                  style=" align-items: flex-start; display: flex; flex-direction: column;"
                >
                  <div
                    style="order: 1;"
                  >
                    <b>Questão:</b> {{ row.item['question'] }}
                  </div>

                  <div
                    style="order: 2;"
                  >
                    <b>Resposta:</b>
                    <p
                      v-for="(desc, k) in row.item['answer'].split('\n')"
                      :key="k"
                    >
                      <span v-html="desc" />
                    </p>
                  </div>
                  <div
                    style="order: 3;"
                    class="text-left"
                  >
                    <b>Nível:</b>
                    {{ row.item['level'] === '1' ? 'Empresa' : row.item['level'] === '2' ? 'Facilitador' : row.item['level'] === '3' ? 'Todos' : row.item['level'] === '4' ? 'Site' : '' }}
                  </div>
                  <div
                    v-if=" row.item['created_at']"
                    style="order: 8;"
                  >
                    <b>Criado em:</b> {{ row.item['created_at'].split("T")[0].split("-").reverse().join("/") }}
                  </div>
                  <div
                    class="buttons"
                    style="order: 9;"
                  >
                    <div
                      class="button primary sm"
                      @click="setEditFaq(row.item['id'])"
                    >
                      <b-icon icon="pencil" /> Editar
                    </div>
                    <div
                      class="button danger sm"
                      @click="() => {
                        deleteFaqId = row.item['id']
                        $bvModal.show('deleteFaq')
                      }"
                    >
                      <b-icon icon="trash" /> Apagar
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="width: 100%;"
                >
                  <b-form
                    v-if="faq && editFaq && editFaqId === row.item['id']"
                    style="text-align: left;"
                    @submit="faqSave"
                  >
                    <b-form-group
                      id="question"
                      label="Question *"
                      label-for="question"
                    >
                      <b-form-textarea
                        id="question"
                        v-model="faq.question"
                        type="text"
                        placeholder="Questão"
                        style="min-height: 200px;"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      id="answer"
                      label="Resposta *"
                      label-for="answer"
                    >
                      <vue-editor v-model="faq.answer" />
                      <!-- <b-form-textarea
                        id="answer"
                        v-model="faq.answer"
                        type="text"
                        placeholder="Resposta"
                        style="min-height: 200px;"
                        required
                      /> -->
                    </b-form-group>
                    <b-form-group
                      label="Grupo de permissões de acesso:"
                    >
                      <b-form-radio-group
                        id="radio-group-2"
                        v-model="faq.level"
                        required
                        name="radio-sub-component"
                      >
                        <b-form-radio value="1">
                          Empresa
                        </b-form-radio>
                        <b-form-radio value="2">
                          Facilitador
                        </b-form-radio>
                        <b-form-radio value="3">
                          Todos
                        </b-form-radio>
                        <b-form-radio value="4">
                          Site
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <div>* Campo de preenchimento obrigatório</div>
                    <div class="buttons justify-content-start">
                      <button
                        class="button primary sm"
                        type="submit"
                        variant="primary"
                      >
                        <b-icon icon="cloud-upload" /> Guardar
                      </button>
                      <button
                        class="button warning sm"
                        variant="danger"
                        @click="() => {
                          editFaq = false
                          editFaqId = null
                        }"
                      >
                        <b-icon
                          icon="x"
                        /> Cancelar
                      </button>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageFaqs"
          :total-rows="rowsFaqs"
          :per-page="perPageFaqs"
          aria-controls="faqs"
        />
      </b-col>
    </b-row>
    <b-modal
      id="deleteFaq"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Apagar FAQ"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Apagar FAQ
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende apagar esta FAQ?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteFaqId = null
              $bvModal.hide('deleteFaq');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button danger sm"
            type="submit"
            @click="deleteFaq"
          >
            <b-icon icon="trash" />
            Apagar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import {
  get, post, put, remove,
} from '../../../services/api';

export default {
  name: 'FaqsTab',
  components: { VueEditor },
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      createNewFaq: false,
      deleteFaqId: null,
      filterFaqs: null,
      faqs: null,
      perPageFaqs: 10,
      filterOnFaqs: ['answer', 'question', 'level'],
      currentPageFaqs: 1,
      editFaq: false,
      editFaqId: null,
      faq: {},
      newFaq: {
        question: '',
        answer: '',
        level: '',
      },
      fieldsFaqs: [
        {
          key: 'question', sortable: true, label: 'Pergunta', thStyle: { width: '30%' }, tdClass: 'align-top',
        },
        {
          key: 'answer', sortable: true, label: 'Resposta', thStyle: { width: '30%' }, tdClass: 'align-top',
        },
        {
          key: 'level',
          sortable: true,
          label: 'Nível',
          formatter: (value) => (value === '1' ? 'Empresa' : value === '2' ? 'Facilitador' : value === '3' ? 'Todos' : value === '4' ? 'Site' : ''),
          thStyle: { width: '30%' },
          tdClass: 'align-top',
        },
        {
          key: 'actions', label: 'Ações', thStyle: { width: '10%' }, tdClass: 'align-top',
        },
      ],
    };
  },
  computed: {
    rowsFaqs() {
      if (this.faqs) {
        return this.faqs.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getFaqs();
    this.setLoading(false);
  },
  methods: {
    async getFaqs() {
      await get('faqs/', { all: true }).then((response) => {
        this.faqs = response.data;
      });
    },
    async newFaqSave(event) {
      event.preventDefault();
      if (this.newFaq.answer !== '' && this.newFaq.question !== '' && this.newFaq.level !== '') {
        await post('faqs/', this.newFaq);

        this.createNewFaq = false;
        this.newFaq = {
          question: '',
          answer: '',
          level: '',
        };
        this.getFaqs();
      }
    },
    async faqSave(event) {
      event.preventDefault();

      await put(`faqs/${this.editFaqId}`, this.faq);
      this.editFaq = false;
      this.editFaqId = null;
      this.getFaqs();
    },
    async deleteFaq() {
      await put(`faqs/${this.deleteFaqId}`, {
        active: 0,
      });
      this.deleteFaqId = null;
      this.$bvModal.hide('deleteFaq');
      this.getFaqs();
    },
    setEditFaq(id) {
      this.faq = this.faqs.find((el) => el.id === id);

      this.editFaqId = id;
      this.editFaq = true;
    },
  },
};
</script>
