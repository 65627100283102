var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"buttons justify-content-start mt-2"},[_c('div',{staticClass:"button primary sm",on:{"click":function () {
            if(_vm.createNewVideo) {
              _vm.createNewVideo = false
              _vm.newVideo = {
                name: '',
                link: '',
              }
            } else {
              _vm.createNewVideo = true

            }
          }}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Adicionar video ")],1)])]),(_vm.createNewVideo)?_c('b-col',[_c('b-form',{staticStyle:{"text-align":"left"},on:{"submit":_vm.newVideoSave}},[_c('b-form-group',{attrs:{"id":"name","label":"Nome *","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","type":"text","placeholder":"Nome do vídeo","required":""},model:{value:(_vm.newVideo.name),callback:function ($$v) {_vm.$set(_vm.newVideo, "name", $$v)},expression:"newVideo.name"}})],1),_c('b-form-group',{attrs:{"id":"link","label":"Link youtube *","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","type":"text","placeholder":"Link youtube","required":""},model:{value:(_vm.newVideo.link),callback:function ($$v) {_vm.$set(_vm.newVideo, "link", $$v)},expression:"newVideo.link"}})],1),_c('div',[_vm._v("* Campo de preenchimento obrigatório")]),_c('div',{staticClass:"buttons justify-content-start"},[_c('button',{staticClass:"button primary sm",attrs:{"type":"submit","variant":"primary"}},[_c('b-icon',{attrs:{"icon":"cloud-upload"}}),_vm._v(" Guardar ")],1),_c('button',{staticClass:"button warning sm",attrs:{"variant":"danger"},on:{"click":function () {
              _vm.createNewVideo = false
              _vm.newVideo = {
                name: '',
                link: '',
              }
            }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1)])],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('b-input-group',{staticClass:"mb-3",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('b-icon',{attrs:{"icon":"search","scale":"0.8"}})],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Pesquisar..."},model:{value:(_vm.filterVideos),callback:function ($$v) {_vm.filterVideos=$$v},expression:"filterVideos"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filterVideos},on:{"click":function($event){_vm.filterVideos = ''}}},[_vm._v(" Limpar ")])],1)],1),(_vm.videos)?_c('b-table',{attrs:{"id":"videos","items":_vm.videos,"per-page":_vm.perPageVideos,"current-page":_vm.currentPageVideos,"fields":_vm.fieldsVideos,"filter":_vm.filterVideos,"filter-included-fields":_vm.filterOnVideos,"borderless":"","hover":"","small":""},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
            var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('b-form-checkbox',{attrs:{"checked":rowSelected,"value":"true"}})]:[_c('b-form-checkbox',{attrs:{"checked":rowSelected}})]]}},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"buttons m-0 p-0 justify-content-start buttons-group"},[_c('div',{staticClass:"button primary xs",on:{"click":row.toggleDetails}},[_c('b-icon',{attrs:{"scale":"1.2","icon":row.detailsShowing ? 'eye-slash' : 'eye'}})],1)])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('div',{staticStyle:{"align-items":"flex-start","display":"flex","flex-direction":"column"}},[(!_vm.editVideo || _vm.editVideoId !== row.item['id'] )?_c('div',{staticStyle:{"align-items":"flex-start","display":"flex","flex-direction":"column"}},[(row.item['name'])?_c('div',{staticStyle:{"order":"1"}},[_c('b',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(row.item['name'])+" ")]):_vm._e(),(row.item['link'])?_c('div',{staticClass:"mt-5 mb-5",staticStyle:{"order":"2"}},[_c('a',{attrs:{"href":("https://www.youtube.com/watch?v=" + (row.item['link'])),"target":"_blank"}},[_vm._v(" https://www.youtube.com/watch?v="+_vm._s(row.item['link'])+" ")])]):_vm._e(),( row.item['created_at'])?_c('div',{staticStyle:{"order":"8"}},[_c('b',[_vm._v("Criado em:")]),_vm._v(" "+_vm._s(row.item['created_at'])+" ")]):_vm._e(),_c('div',{staticClass:"buttons",staticStyle:{"order":"9"}},[_c('div',{staticClass:"button primary sm",on:{"click":function($event){return _vm.setEditVideo(row.item['id'])}}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v(" Editar ")],1),(row.item.active)?_c('div',{staticClass:"button danger sm",on:{"click":function () {
                      _vm.deleteVideoId = row.item['id'];
                      _vm.$bvModal.show('deleteVideo')
                    }}},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Desativar ")],1):_c('div',{staticClass:"button success sm",on:{"click":function () {
                      _vm.deleteVideoId = row.item['id'];
                      _vm.$bvModal.show('activeVideo')
                    }}},[_c('b-icon',{attrs:{"icon":"check"}}),_vm._v(" Ativar ")],1)])]):_c('div',{staticStyle:{"width":"100%"}},[(_vm.video && _vm.editVideo && _vm.editVideoId === row.item['id'])?_c('b-form',{staticStyle:{"text-align":"left"},on:{"submit":_vm.videoSave}},[_c('b-form-group',{attrs:{"id":"name","label":"Nome *","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","type":"text","placeholder":"Nome do vídeo","required":""},model:{value:(_vm.video.name),callback:function ($$v) {_vm.$set(_vm.video, "name", $$v)},expression:"video.name"}})],1),_c('b-form-group',{attrs:{"id":"link","label":"Link youtube *","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","type":"text","placeholder":"Link youtube","required":""},model:{value:(_vm.video.link),callback:function ($$v) {_vm.$set(_vm.video, "link", $$v)},expression:"video.link"}})],1),_c('div',[_vm._v("* Campo de preenchimento obrigatório")]),_c('div',{staticClass:"buttons justify-content-start"},[_c('button',{staticClass:"button primary sm",attrs:{"type":"submit","variant":"primary"}},[_c('b-icon',{attrs:{"icon":"cloud-upload"}}),_vm._v(" Guardar ")],1),_c('button',{staticClass:"button warning sm",attrs:{"variant":"danger"},on:{"click":function () {
                        _vm.editVideo = false
                        _vm.editVideoId = null
                      }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1)])],1):_vm._e()],1)])])]}}],null,false,3714963366)}):_vm._e(),_c('b-pagination',{attrs:{"total-rows":_vm.rowsVideos,"per-page":_vm.perPageVideos,"aria-controls":"videos"},model:{value:(_vm.currentPageVideos),callback:function ($$v) {_vm.currentPageVideos=$$v},expression:"currentPageVideos"}})],1)],1),_c('b-modal',{attrs:{"id":"deleteVideo","header-class":"border-bottom-0","footer-class":"border-top-0","hide-header":"","centered":"","title":"Desativar vídeo","hide-footer":"","size":"lg"}},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Desativar vídeo ")]),_c('div',{staticClass:"text-dark text-center"},[_vm._v(" Tem a certeza que pretende desativar este vídeo? ")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button secondary sm",attrs:{"type":"submit"},on:{"click":function () {
            _vm.deleteVideoId = null
            _vm.$bvModal.hide('deleteVideo');
          }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"button danger sm",attrs:{"type":"submit"},on:{"click":_vm.deleteVideo}},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Desativar ")],1)])])]),_c('b-modal',{attrs:{"id":"activeVideo","header-class":"border-bottom-0","footer-class":"border-top-0","hide-header":"","centered":"","title":"Ativar vídeo","hide-footer":"","size":"lg"}},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Ativar vídeo ")]),_c('div',{staticClass:"text-dark text-center"},[_vm._v(" Tem a certeza que pretende ativar este vídeo? ")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button secondary sm",attrs:{"type":"submit"},on:{"click":function () {
            _vm.deleteVideoId = null
            _vm.$bvModal.hide('activeVideo');
          }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"button success sm",attrs:{"type":"submit"},on:{"click":_vm.activeVideo}},[_c('b-icon',{attrs:{"icon":"check"}}),_vm._v(" Ativar ")],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }