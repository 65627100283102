<template>
  <div>
    <b-row>
      <b-col>
        <b-form
          @submit="uploadFile"
        >
          <b-form-file
            id="file"
            v-model="uploadedFile.file"
            :state="uploadedFile.file ? true : null "
            :name="uploadedFile.file"
            placeholder="Selecione um ficheiro ou mova-o para aqui..."
            drop-placeholder="Largue o ficheiro aqui..."
          />
          <div class="text-dark-grey">
            Apenas são permitidos ficheiros até 5mb.
          </div>
          <div class="my-3">
            Ficheiro: {{ uploadedFile.file ? uploadedFile.file.name : 'Nenhum ficheiro carregado' }}
          </div>
          <b-form-group
            label="Grupo de permissões de acesso:"
          >
            <b-form-radio-group
              id="radio-group-2"
              v-model="uploadedFile.permissions"
              name="radio-sub-component"
            >
              <b-form-radio value="1">
                Administrador da plataforma
              </b-form-radio>
              <b-form-radio value="3">
                Administrador da empresa
              </b-form-radio>
              <b-form-radio value="4">
                Facilitador
              </b-form-radio>
              <b-form-radio value="2">
                Todos
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div class="buttons justify-content-start">
            <b-button
              type="submit"
              class="button primary sm"
              variant="primary"
            >
              <b-icon icon="cloud-upload" /> Carregar
            </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="title">
        Documentos carregados
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-4"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterDocuments"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterDocuments"
              @click="filterDocuments = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          id="documents"
          :items="documents"
          :per-page="perPageDocuments"
          :current-page="currentPageDocuments"
          :fields="fieldsDocuments"
          :filter="filterDocuments"
          :filter-included-fields="filterOnDocuments"
          borderless
          hover
          small
          show-empty
          empty-text="Sem documentos"
        >
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card class="text-left">
              <div class="buttons justify-content-start">
                <div
                  class="button primary sm"
                  @click="downloadDocument(row.item['id'])"
                >
                  <b-icon icon="download" />
                  Download
                </div>
              </div>

              <b-form-group
                :set="newPermission.permissions[row.item['id']] = row.item['permissions']"
                label="Grupo de permissões de acesso:"
              >
                <b-form-radio-group
                  :id="'permissions_'+row.item['id']"
                  v-model="newPermission.permissions[row.item['id']]"
                  :checked="row.item['permissions']"
                  name="radio-sub-component"
                >
                  <b-form-radio
                    value="1"
                  >
                    Administrador da plataforma
                  </b-form-radio>
                  <b-form-radio
                    value="3"
                  >
                    Administrador da empresa
                  </b-form-radio>
                  <b-form-radio
                    value="4"
                  >
                    Facilitador
                  </b-form-radio>
                  <b-form-radio
                    value="2"
                  >
                    Todos
                  </b-form-radio>
                </b-form-radio-group>
                <div class="buttons justify-content-start">
                  <div
                    class="button primary mt-2 sm"
                    variant="primary"
                    @click="changePermissions(row.item['id'])"
                  >
                    <b-icon icon="arrow-repeat" />
                    Alterar permissões
                  </div>
                </div>
              </b-form-group>
              <div class="buttons justify-content-start">
                <div
                  class="button danger sm"
                  @click="() => {
                    deleteDocumentId = row.item['id']
                    $bvModal.show('deleteDocument');
                  }"
                >
                  <b-icon
                    icon="trash"
                  />
                  Remover
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageDocuments"
          :total-rows="rowsDocuments"
          :per-page="perPageDocuments"
          aria-controls="users"
        />
      </b-col>
    </b-row>

    <b-modal
      id="deleteDocument"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Remover documento"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Remover documento
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende remover este documento?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteDocumentId = null
              $bvModal.hide('deleteDocument');
            }"
          >
            <b-icon
              icon="x"
            />
            Cancelar
          </button>
          <button
            class="button danger sm"
            type="submit"
            @click="deleteDocument"
          >
            <b-icon
              icon="trash"
            />
            Remover
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  get, put, remove, postFile,
} from '../../../services/api';

export default {
  name: 'DocumentsTab',
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      filterDocuments: null,
      filterOnDocuments: ['name'],
      deleteDocumentId: null,
      documents: null,
      perPageDocuments: 10,
      currentPageDocuments: 1,
      fieldsDocuments: [
        {
          key: 'name', sortable: true, label: 'Nome', thStyle: { width: '45%' },
        },
        {
          key: 'permissions',
          sortable: true,
          thStyle: { width: '45%' },
          label: 'Permissões',
          formatter: (value) => this.permissions[value - 1],
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      uploadedFile: {
        file: undefined,
        permissions: 4,
      },
      newPermission: {
        permissions: {},
      },
      permissions: [
        'Administrador',
        'Todos',
        'Gestor da empresa',
        'Facilitador',
      ],
    };
  },
  computed: {
    rowsDocuments() {
      if (this.documents) {
        return this.documents.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getDocuments();
    this.setLoading(false);
  },
  methods: {
    async changePermissions(id) {
      await put(`document/${id}`, {
        permissions: this.newPermission.permissions[id],
      }).then(() => {
        this.getDocuments();
      });
    },
    async deleteDocument() {
      await remove(`document/${this.deleteDocumentId}`).then(() => {
        this.deleteDocumentId = null;
        this.getDocuments();
        this.$bvModal.hide('deleteDocument');
      });
    },
    async downloadDocument(id) {
      const file = this.documents.filter((element) => element.id === id)[0];
      const linkSource = `data:application/${file.name.split('.')[-1]};base64,${file.file}`;
      const downloadLink = document.createElement('a');
      const fileName = file.name;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    async uploadFile(event) {
      event.preventDefault();

      this.setLoading(true);

      const formData = new FormData();
      formData.append('file', this.uploadedFile.file, this.uploadedFile.file.name);
      formData.append('permissions', this.uploadedFile.permissions);
      await postFile('document', formData).then(() => {
        this.getDocuments();
      });
      this.setLoading(false);
    },
    async getDocuments() {
      await get('document/').then((response) => {
        this.documents = response.data;
      });
    },
  },
};
</script>
