var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-input-group',{staticClass:"mb-3",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('b-icon',{attrs:{"icon":"search","scale":"0.8"}})],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Pesquisar..."},model:{value:(_vm.filterResults),callback:function ($$v) {_vm.filterResults=$$v},expression:"filterResults"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filterResults},on:{"click":function($event){_vm.filterResults = ''}}},[_vm._v(" Limpar ")])],1)],1),(_vm.results)?_c('b-table',{attrs:{"id":"results","items":_vm.results,"per-page":_vm.perPageResults,"current-page":_vm.currentPageResults,"fields":_vm.fieldsResults,"filter":_vm.filterResults,"filter-included-fields":_vm.filterOnResults,"borderless":"","hover":"","small":""},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('b-form-checkbox',{attrs:{"checked":rowSelected,"value":"true"}})]:[_c('b-form-checkbox',{attrs:{"checked":rowSelected}})]]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('div',{staticStyle:{"align-items":"flex-start","display":"flex","flex-direction":"column"}},[(!_vm.editResult || _vm.editResultId !== row.item['id'] )?_c('div',{staticStyle:{"align-items":"flex-start","display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"order":"1"}},[_c('b',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(row.item['label'])+" ")]),( row.item['created_at'])?_c('div',{staticStyle:{"order":"8"}},[_c('b',[_vm._v("Criado em:")]),_vm._v(" "+_vm._s(row.item['created_at'])+" ")]):_vm._e()]):_c('div',{staticStyle:{"width":"100%"}},[(_vm.result && _vm.editResult && _vm.editResultId === row.item['id'])?_c('b-form',{staticStyle:{"text-align":"left"},on:{"submit":_vm.resultSave}},[_c('b-form-group',{attrs:{"id":"label","label":"Nome *","label-for":"label"}},[_c('b-form-input',{attrs:{"id":"label","type":"text","placeholder":"Nome do Resultado","required":""},model:{value:(_vm.result.label),callback:function ($$v) {_vm.$set(_vm.result, "label", $$v)},expression:"result.label"}})],1),_c('div',[_vm._v("* Campo de preenchimento obrigatório")]),_c('div',{staticClass:"buttons justify-content-start"},[_c('button',{staticClass:"button primary sm",attrs:{"type":"submit","variant":"primary"}},[_c('b-icon',{attrs:{"icon":"cloud-upload"}}),_vm._v(" Guardar ")],1),_c('button',{staticClass:"button warning sm",attrs:{"variant":"danger"},on:{"click":function () {
                        _vm.editResult = false
                        _vm.editResultId = null
                      }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1)])],1):_vm._e()],1)])])]}}],null,false,766263955)}):_vm._e(),_c('b-pagination',{attrs:{"total-rows":_vm.rowsResults,"per-page":_vm.perPageResults,"aria-controls":"results"},model:{value:(_vm.currentPageResults),callback:function ($$v) {_vm.currentPageResults=$$v},expression:"currentPageResults"}})],1)],1),_c('b-modal',{attrs:{"id":"deleteResult","header-class":"border-bottom-0","footer-class":"border-top-0","hide-header":"","centered":"","title":"desativar resultado","hide-footer":"","size":"lg"}},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Desativar Resultado ")]),_c('div',{staticClass:"text-dark text-center"},[_vm._v(" Tem a certeza que pretende desativar este resultado? ")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button secondary sm",attrs:{"type":"submit"},on:{"click":function () {
            _vm.deleteResultId = null
            _vm.$bvModal.hide('deleteResult');
          }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"button danger sm",attrs:{"type":"submit"},on:{"click":_vm.deleteResult}},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Desativar ")],1)])])]),_c('b-modal',{attrs:{"id":"activeResult","header-class":"border-bottom-0","footer-class":"border-top-0","hide-header":"","centered":"","title":"ativar resultado","hide-footer":"","size":"lg"}},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Ativar Resultado ")]),_c('div',{staticClass:"text-dark text-center"},[_vm._v(" Tem a certeza que pretende ativar este resultado? ")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button secondary sm",attrs:{"type":"submit"},on:{"click":function () {
            _vm.deleteResultId = null
            _vm.$bvModal.hide('activeResult');
          }}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"button success sm",attrs:{"type":"submit"},on:{"click":_vm.activeResult}},[_c('b-icon',{attrs:{"icon":"check"}}),_vm._v(" Ativar ")],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }