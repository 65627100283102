<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="buttons justify-content-start mt-2">
          <div
            class="button primary sm"

            @click="() => {
              if(createNewTestimonial) {
                createNewTestimonial = false
                newTestimonial = {
                  author: '',
                  image: '',
                  image_extension: '',
                  company: '',
                  company_link: '',
                  company_image: '',
                  company_image_extension: '',
                  emphasis: '',
                  text: '',
                }
              } else {
                createNewTestimonial = true

              }
            }"
          >
            <b-icon icon="plus" /> Adicionar testemunho
          </div>
        </div>
      </b-col>
      <b-col v-if="createNewTestimonial">
        <b-form
          style="text-align: left;"
          @submit="newTestimonialSave"
        >
          <b-form-group
            id="author"
            label="Autor *"
            label-for="author"
          >
            <b-form-input
              id="author"
              v-model="newTestimonial.author"
              type="text"
              placeholder="Nome do autor"
              required
            />
          </b-form-group>
          <b-form-group
            label="Foto *"
            label-for="image"
          >
            <b-form-file
              id="image"
              required
              :state="Boolean(newTestimonial.image)"
              :name="`${newTestimonial.author}_${newTestimonial.company}`"
              :placeholder="newTestimonial.image ?
                `${newTestimonial.author}_${newTestimonial.company}.${newTestimonial.image_extension}` :
                'Selecione um ficheiro ou mova-o para aqui...'"
              drop-placeholder="Largue o ficheiro aqui..."
              accept=".jpg, .png, .svg"
              @input="(value) => {
                uploadNewTestimonialImage(value)
              }"
            />
          </b-form-group>

          <b-form-group
            id="emphasis"
            label="Destaque *"
            label-for="emphasis"
          >
            <b-form-input
              id="emphasis"
              v-model="newTestimonial.emphasis"
              type="text"
              placeholder="Destaque"
              required
            />
          </b-form-group>
          <b-form-group
            id="text"
            label="Testemunho *"
            label-for="text"
          >
            <b-form-textarea
              id="text"
              v-model="newTestimonial.text"
              placeholder="Testemunho"
              required
              style="min-height: 200px;"
            />
          </b-form-group>

          <b-form-group
            id="company"
            label="Empresa *"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="newTestimonial.company"
              type="text"
              placeholder="Nome da empresa"
              required
            />
          </b-form-group>

          <b-form-group
            id="company_link"
            label="Website P"
            label-for="company_link"
          >
            <b-form-input
              id="company_link"
              v-model="newTestimonial.company_link"
              type="text"
              placeholder="Website da empresa"
              required
            />
          </b-form-group>

          <b-form-group
            label="Logo da empresa *"
            label-for="company_image"
          >
            <b-form-file
              id="company_image"
              required
              :state="Boolean(newTestimonial.company_image)"
              :name="`${newTestimonial.author}_${newTestimonial.company}`"
              :placeholder="newTestimonial.company_image ?
                `${newTestimonial.company}.${newTestimonial.image_extension}` :
                'Selecione um ficheiro ou mova-o para aqui...'"
              drop-placeholder="Largue o ficheiro aqui..."
              accept=".jpg, .png, .svg"
              @input="(value) => {
                uploadNewTestimonialCompanyImage(value)
              }"
            />
          </b-form-group>
          <div>* Campo de preenchimento obrigatório</div>
          <div class="buttons justify-content-start">
            <button
              class="button primary sm"
              type="submit"
              variant="primary"
            >
              <b-icon
                icon="cloud-upload"
              /> Guardar
            </button>
            <button
              class="button warning sm"
              variant="danger"
              @click="() => {
                createNewTestimonial = false
                newTestimonial = {
                  author: '',
                  image: '',
                  image_extension: '',
                  company: '',
                  company_link: '',
                  company_image: '',
                  company_image_extension: '',
                  emphasis: '',
                  text: '',
                }
              }"
            >
              <b-icon
                icon="x"
              />
              Cancelar
            </button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <b-form-input
            id="filter-input"
            v-model="filterTestimonials"
            type="search"
            placeholder="Procurar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterTestimonials"
              @click="filterTestimonials = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          v-if="testimonials"
          id="testimonials"
          :items="testimonials"
          :per-page="perPageTestimonials"
          :current-page="currentPageTestimonials"
          :fields="fieldsTestimonials"
          :filter="filterTestimonials"
          :filter-included-fields="filterOnTestimonials"
          borderless
          hover
          small
        >
          <template
            #cell(selected)="{ rowSelected }"
          >
            <template
              v-if="rowSelected"
            >
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="rowSelected"
              />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <div style=" align-items: flex-start; display: flex; flex-direction: column;">
                <div
                  v-if="!editTestimonial || editTestimonialId !== row.item['id'] "
                  style=" align-items: flex-start; display: flex; flex-direction: column;"
                >
                  <div
                    style="order: 1;"
                  >
                    <b>Autor:</b> {{ row.item['author'] }}
                  </div>
                  <div
                    style="order: 2;"
                    class="mt-5 mb-5"
                  >
                    <img
                      height="100px"
                      :src="`${row.item['image']}`"
                    >
                  </div>
                  <div
                    style="order: 3;"
                  >
                    <b>Destaque:</b> {{ row.item['emphasis'] }}
                  </div>
                  <div
                    style="order: 4;"
                    class="text-left"
                  >
                    <b>Testemunho:</b>
                    {{ row.item['text'] }}
                  </div>
                  <div
                    style="order: 5;"
                    class="text-left"
                  >
                    <b>Empresa:</b>
                    {{ row.item['company'] }}
                  </div>
                  <div
                    style="order: 6;"
                    class="text-left"
                  >
                    <b>Website:</b>
                    <a
                      :href="row.item['company_link']"
                      target="_blank"
                    >{{ row.item['company_link'] }}</a>
                  </div>
                  <div
                    style="order: 7;"
                    class="mt-5 mb-5"
                  >
                    <img
                      height="100px"
                      :src="`${row.item['company_image']}`"
                    >
                  </div>
                  <div
                    v-if=" row.item['created_at']"
                    style="order: 8;"
                  >
                    <b>Criado em:</b> {{ row.item['created_at'] }}
                  </div>
                  <div
                    class="buttons"
                    style="order: 9;"
                  >
                    <div
                      class="button primary sm"
                      @click="setEditTestimonial(row.item['id'])"
                    >
                      <b-icon icon="pencil" /> Editar
                    </div>
                    <div
                      v-if="row.item.active"
                      class="button danger sm"
                      @click="() => {
                        deleteTestimonialId = row.item['id']
                        $bvModal.show('deleteTestimonial')
                      }"
                    >
                      <b-icon icon="trash" /> Desativar
                    </div>
                    <div
                      v-else
                      class="button success sm"
                      @click="() => {
                        deleteTestimonialId = row.item['id']
                        $bvModal.show('activeTestimonial')
                      }"
                    >
                      <b-icon icon="check" /> Ativar
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="width: 100%;"
                >
                  <b-form
                    v-if="testimonial && editTestimonial && editTestimonialId === row.item['id']"
                    style="text-align: left;"
                    @submit="testimonialSave"
                  >
                    <b-form-group
                      id="author"
                      label="Autor *"
                      label-for="author"
                    >
                      <b-form-input
                        id="author"
                        v-model="testimonial.author"
                        type="text"
                        placeholder="Nome do autor"
                        required
                      />
                    </b-form-group>
                    <b-form-group
                      label="Foto"
                      label-for="image"
                    >
                      <b-form-file
                        id="image"
                        :state="Boolean(testimonial.image)"
                        :name="`${testimonial.author}_${testimonial.company}`"
                        :placeholder="testimonial.image ?
                          `${testimonial.author}_${testimonial.company}.${testimonial.image_extension}` :
                          'Selecione um ficheiro ou mova-o para aqui...'"
                        drop-placeholder="Largue o ficheiro aqui..."
                        accept=".jpg, .png, .svg"
                        @input="(value) => {
                          uploadTestimonialImage(value)
                        }"
                      />
                    </b-form-group>

                    <b-form-group
                      id="emphasis"
                      label="Destaque *"
                      label-for="emphasis"
                    >
                      <b-form-input
                        id="emphasis"
                        v-model="testimonial.emphasis"
                        type="text"
                        placeholder="Destaque"
                        required
                      />
                    </b-form-group>
                    <b-form-group
                      id="text"
                      label="Testemunho *"
                      label-for="text"
                    >
                      <b-form-textarea
                        id="text"
                        v-model="testimonial.text"
                        placeholder="Testemunho"
                        required
                        style="min-height: 200px;"
                      />
                    </b-form-group>

                    <b-form-group
                      id="company"
                      label="Empresa *"
                      label-for="company"
                    >
                      <b-form-input
                        id="company"
                        v-model="testimonial.company"
                        type="text"
                        placeholder="Nome da empresa"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      id="company_link"
                      label="Website *"
                      label-for="company_link"
                    >
                      <b-form-input
                        id="company_link"
                        v-model="testimonial.company_link"
                        type="text"
                        placeholder="Website da empresa"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      label="Logo da empresa"
                      label-for="company_image"
                    >
                      <b-form-file
                        id="company_image"
                        :state="Boolean(testimonial.company_image)"
                        :name="`${testimonial.author}_${testimonial.company}`"
                        :placeholder="testimonial.company_image ?
                          `${testimonial.company}.${testimonial.image_extension}` :
                          'Selecione um ficheiro ou mova-o para aqui...'"
                        drop-placeholder="Largue o ficheiro aqui..."
                        accept=".jpg, .png, .svg"
                        @input="(value) => {
                          uploadTestimonialCompanyImage(value)
                        }"
                      />
                    </b-form-group>
                    <div>* Campo de preenchimento obrigatório</div>
                    <div class="buttons justify-content-start">
                      <button
                        class="button primary sm"
                        type="submit"
                        variant="primary"
                      >
                        <b-icon icon="cloud-upload" /> Guardar
                      </button>
                      <button
                        class="button warning sm"
                        variant="danger"
                        @click="() => {
                          editTestimonial = false
                          editTestimonialId = null
                        }"
                      >
                        <b-icon
                          icon="x"
                        /> Cancelar
                      </button>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageTestimonials"
          :total-rows="rowsTestimonials"
          :per-page="perPageTestimonials"
          aria-controls="testimonials"
        />
      </b-col>
    </b-row>
    <b-modal
      id="deleteTestimonial"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Desativar testemunho"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Desativar testemunho
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende desativar este testemunho?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteTestimonialId = null
              $bvModal.hide('deleteTestimonial');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button danger sm"
            type="submit"
            @click="deleteTestimonial"
          >
            <b-icon icon="trash" />
            Desativar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="activeTestimonial"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Ativar testemunho"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Ativar testemunho
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende ativar este testemunho?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteTestimonialId = null
              $bvModal.hide('activeTestimonial');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button success sm"
            type="submit"
            @click="activeTestimonial"
          >
            <b-icon icon="check" />
            Ativar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  get, post, put, remove,
} from '../../../services/api';

export default {
  name: 'TestimonialsTab',
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      createNewTestimonial: false,
      deleteTestimonialId: null,
      filterTestimonials: null,
      testimonials: null,
      perPageTestimonials: 10,
      filterOnTestimonials: ['author', 'company'],
      currentPageTestimonials: 1,
      editTestimonial: false,
      editTestimonialId: null,
      testimonial: {},
      newTestimonial: {
        author: '',
        image: '',
        image_extension: '',
        company: '',
        company_link: '',
        company_image: '',
        company_image_extension: '',
        emphasis: '',
        text: '',
      },
      fieldsTestimonials: [
        {
          key: 'author', sortable: true, label: 'Autor', thStyle: { width: '30%' },
        },
        {
          key: 'company', sortable: true, label: 'Empresa', thStyle: { width: '50%' },
        },
        {
          key: 'active',
          sortable: true,
          label: 'Ativo',
          formatter: (value) => (value ? 'Sim' : 'Não'),
          thStyle: { width: '10%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
    };
  },
  computed: {
    rowsTestimonials() {
      if (this.testimonials) {
        return this.testimonials.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getTestimonials();
    this.setLoading(false);
  },
  methods: {
    async getTestimonials() {
      await get('testimonials/', { all: true }).then((response) => {
        this.testimonials = response.data;
      });
    },
    uploadNewTestimonialImage(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.newTestimonial.image_extension = value.name.split('.')[value.name.split('.').length - 1];
        this.newTestimonial.image = reader.result;
      };
    },
    uploadTestimonialImage(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.testimonial.image_extension = value.name.split('.')[value.name.split('.').length - 1];
        this.testimonial.image = reader.result;
      };
    },
    uploadNewTestimonialCompanyImage(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.newTestimonial.company_image_extension = value.name.split('.')[value.name.split('.').length - 1];
        this.newTestimonial.company_image = reader.result;
      };
    },
    async newTestimonialSave(event) {
      event.preventDefault();

      await post('testimonials/', this.newTestimonial);

      this.createNewTestimonial = false;
      this.newTestimonial = null;
      this.getTestimonials();
    },
    async testimonialSave(event) {
      event.preventDefault();

      await put(`testimonials/${this.editTestimonialId}`, this.testimonial);
      this.editTestimonial = false;
      this.editTestimonialid = null;
      this.getTestimonials();
    },
    async activeTestimonial() {
      await put(`testimonials/${this.deleteTestimonialId}`, {
        active: 1,
      });
      this.deleteTestimonialId = null;
      this.$bvModal.hide('activeTestimonial');
      this.getTestimonials();
    },
    async deleteTestimonial() {
      await put(`testimonials/${this.deleteTestimonialId}`, {
        active: 0,
      });
      this.deleteTestimonialId = null;
      this.$bvModal.hide('deleteTestimonial');
      this.getTestimonials();
    },
    uploadTestimonialCompanyImage(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.testimonial.company_image_extension = value.name.split('.')[value.name.split('.').length - 1];
        this.testimonial.company_image = reader.result;
      };
    },
    setEditTestimonial(id) {
      this.testimonial = this.testimonials.find((el) => el.id === id);

      this.editTestimonialId = id;
      this.editTestimonial = true;
    },
  },
};
</script>
