<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="buttons justify-content-start mt-2">
          <div
            class="button primary sm"

            @click="() => {
              if(createNewVideo) {
                createNewVideo = false
                newVideo = {
                  name: '',
                  link: '',
                }
              } else {
                createNewVideo = true

              }
            }"
          >
            <b-icon icon="plus" /> Adicionar video
          </div>
        </div>
      </b-col>
      <b-col v-if="createNewVideo">
        <b-form
          style="text-align: left;"
          @submit="newVideoSave"
        >
          <b-form-group
            id="name"
            label="Nome *"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="newVideo.name"
              type="text"
              placeholder="Nome do vídeo"
              required
            />
          </b-form-group>

          <b-form-group
            id="link"
            label="Link youtube *"
            label-for="link"
          >
            <b-form-input
              id="link"
              v-model="newVideo.link"
              type="text"
              placeholder="Link youtube"
              required
            />
          </b-form-group>
          <div>* Campo de preenchimento obrigatório</div>
          <div class="buttons justify-content-start">
            <button
              class="button primary sm"
              type="submit"
              variant="primary"
            >
              <b-icon
                icon="cloud-upload"
              /> Guardar
            </button>
            <button
              class="button warning sm"
              variant="danger"
              @click="() => {
                createNewVideo = false
                newVideo = {
                  name: '',
                  link: '',
                }
              }"
            >
              <b-icon
                icon="x"
              /> Cancelar
            </button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterVideos"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterVideos"
              @click="filterVideos = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          v-if="videos"
          id="videos"
          :items="videos"
          :per-page="perPageVideos"
          :current-page="currentPageVideos"
          :fields="fieldsVideos"
          :filter="filterVideos"
          :filter-included-fields="filterOnVideos"
          borderless
          hover
          small
        >
          <template
            #cell(selected)="{ rowSelected }"
          >
            <template
              v-if="rowSelected"
            >
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="rowSelected"
              />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <div style=" align-items: flex-start; display: flex; flex-direction: column;">
                <div
                  v-if="!editVideo || editVideoId !== row.item['id'] "
                  style=" align-items: flex-start; display: flex; flex-direction: column;"
                >
                  <div
                    v-if="row.item['name']"
                    style="order: 1;"
                  >
                    <b>Nome:</b> {{ row.item['name'] }}
                  </div>
                  <div
                    v-if="row.item['link']"
                    style="order: 2;"
                    class="mt-5 mb-5"
                  >
                    <a
                      :href="`https://www.youtube.com/watch?v=${row.item['link']}`"
                      target="_blank"
                    >
                      https://www.youtube.com/watch?v={{ row.item['link'] }}
                    </a>
                  </div>
                  <div
                    v-if=" row.item['created_at']"
                    style="order: 8;"
                  >
                    <b>Criado em:</b> {{ row.item['created_at'] }}
                  </div>
                  <div
                    class="buttons"
                    style="order: 9;"
                  >
                    <div
                      class="button primary sm"
                      @click="setEditVideo(row.item['id'])"
                    >
                      <b-icon icon="pencil" /> Editar
                    </div>
                    <div
                      v-if="row.item.active"
                      class="button danger sm"
                      @click="() => {
                        deleteVideoId = row.item['id'];
                        $bvModal.show('deleteVideo')
                      }"
                    >
                      <b-icon icon="trash" /> Desativar
                    </div>
                    <div
                      v-else
                      class="button success sm"
                      @click="() => {
                        deleteVideoId = row.item['id'];
                        $bvModal.show('activeVideo')
                      }"
                    >
                      <b-icon icon="check" /> Ativar
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="width: 100%;"
                >
                  <b-form
                    v-if="video && editVideo && editVideoId === row.item['id']"
                    style="text-align: left;"
                    @submit="videoSave"
                  >
                    <b-form-group
                      id="name"
                      label="Nome *"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="video.name"
                        type="text"
                        placeholder="Nome do vídeo"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      id="link"
                      label="Link youtube *"
                      label-for="link"
                    >
                      <b-form-input
                        id="link"
                        v-model="video.link"
                        type="text"
                        placeholder="Link youtube"
                        required
                      />
                    </b-form-group>
                    <div>* Campo de preenchimento obrigatório</div>
                    <div class="buttons justify-content-start">
                      <button
                        class="button primary sm"
                        type="submit"
                        variant="primary"
                      >
                        <b-icon icon="cloud-upload" /> Guardar
                      </button>
                      <button
                        class="button warning sm"
                        variant="danger"
                        @click="() => {
                          editVideo = false
                          editVideoId = null
                        }"
                      >
                        <b-icon
                          icon="x"
                        /> Cancelar
                      </button>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageVideos"
          :total-rows="rowsVideos"
          :per-page="perPageVideos"
          aria-controls="videos"
        />
      </b-col>
    </b-row>
    <b-modal
      id="deleteVideo"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Desativar vídeo"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Desativar vídeo
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende desativar este vídeo?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteVideoId = null
              $bvModal.hide('deleteVideo');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button danger sm"
            type="submit"
            @click="deleteVideo"
          >
            <b-icon icon="trash" />
            Desativar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="activeVideo"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Ativar vídeo"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Ativar vídeo
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende ativar este vídeo?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteVideoId = null
              $bvModal.hide('activeVideo');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button success sm"
            type="submit"
            @click="activeVideo"
          >
            <b-icon icon="check" />
            Ativar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  get, post, put, remove,
} from '../../../services/api';

export default {
  name: 'VídeosTab',
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentPageVideos: 1,
      filterOnVideos: ['link', 'name'],
      perPageVideos: 10,
      videos: null,
      deleteVideoId: null,
      filterVideos: null,
      fieldsVideos: [
        {
          key: 'name', sortable: true, label: 'Nome', thStyle: { width: '30%' },
        },

        {
          key: 'link',
          sortable: true,
          label: 'Link',
          formatter: (value) => `https://www.youtube.com/watch?v=${value}`,
          thStyle: { width: '50%' },
        },
        {
          key: 'active',
          sortable: true,
          label: 'Ativo',
          formatter: (value) => (value ? 'Sim' : 'Não'),
          thStyle: { width: '10%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      createNewVideo: false,
      editVideo: false,
      editVideoId: null,
      newVideo: {
        link: '',
        name: '',
      },
    };
  },
  computed: {
    rowsVideos() {
      if (this.videos) {
        return this.videos.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getVideos();
    this.setLoading(false);
  },
  methods: {
    setEditVideo(id) {
      this.video = this.videos.find((el) => el.id === id);
      this.video.link = `https://www.youtube.com/watch?v=${this.video.link}`;
      this.editVideoId = id;
      this.editVideo = true;
    },
    async deleteVideo() {
      await put(`videos/${this.deleteVideoId}`, {
        active: 0,
      });
      this.deleteVideoId = null;
      this.getVideos();
      this.$bvModal.hide('deleteVideo');
    },
    async activeVideo() {
      await put(`videos/${this.deleteVideoId}`, {
        active: 1,
      });
      this.deleteVideoId = null;
      this.getVideos();
      this.$bvModal.hide('activeVideo');
    },
    async videoSave(event) {
      event.preventDefault();
      await put(`videos/${this.editVideoId}`, {
        name: this.video.name,
        link: this.video.link.split('v=')[1],
      });
      this.editVideo = false;
      this.editVideoId = null;
      this.getVideos();
    },
    async newVideoSave(event) {
      event.preventDefault();
      await post('videos/', {
        name: this.newVideo.name,
        link: this.newVideo.link.split('v=')[1],
      });
      this.createNewVideo = false;
      this.editVideo = null;
      this.getVideos();
    },
    async getVideos() {
      await get('videos/', { all: true }).then((response) => {
        this.videos = response.data;
      });
    },
  },
};
</script>
