<template>
  <div>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterResults"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterResults"
              @click="filterResults = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          v-if="results"
          id="results"
          :items="results"
          :per-page="perPageResults"
          :current-page="currentPageResults"
          :fields="fieldsResults"
          :filter="filterResults"
          :filter-included-fields="filterOnResults"
          borderless
          hover
          small
        >
          <template
            #cell(selected)="{ rowSelected }"
          >
            <template
              v-if="rowSelected"
            >
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="rowSelected"
              />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <div style=" align-items: flex-start; display: flex; flex-direction: column;">
                <div
                  v-if="!editResult || editResultId !== row.item['id'] "
                  style=" align-items: flex-start; display: flex; flex-direction: column;"
                >
                  <div
                    style="order: 1;"
                  >
                    <b>Nome:</b> {{ row.item['label'] }}
                  </div>
                  <div
                    v-if=" row.item['created_at']"
                    style="order: 8;"
                  >
                    <b>Criado em:</b> {{ row.item['created_at'].split("T")[0].split("-").reverse().join("/") }}
                  </div>
                  <div
                    class="buttons"
                    style="order: 9;"
                  >
                    <div
                      class="button primary sm"
                      @click="setEditResult(row.item['id'])"
                    >
                      <b-icon icon="pencil" /> Editar
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="width: 100%;"
                >
                  <b-form
                    v-if="result && editResult && editResultId === row.item['id']"
                    style="text-align: left;"
                    @submit="resultSave"
                  >
                    <b-form-group
                      id="label"
                      label="Nome *"
                      label-for="label"
                    >
                      <b-form-input
                        id="label"
                        v-model="result.label"
                        type="text"
                        placeholder="Nome do resultado"
                        required
                      />
                    </b-form-group>

                    <div
                      style="margin-left: 15px; margin-bottom: 15px"
                      class="d-flex flex-column"
                    >
                      <label style="font-size: 16px; font-weight: 600">Passos</label>
                      <b-form-group label="Nº de Passos">
                        <b-form-radio-group
                          v-model="result.number_of_steps"
                          :options="[
                            { text: '5', value: 5, disabled: result.steps.length > 5},
                            { text: '3', value: 3, disabled: result.steps.length > 3 },
                          ]"
                        />
                      </b-form-group>
                      <div
                        v-for="(step, i) in result.steps"
                        :key="i"
                        class="d-flex flex-row align-items-end"
                      >
                        <b-form-group
                          id="step"
                          style="flex: 1"
                          :label="i === 0 ? 'Nome *' : ''"
                          label-for="step"
                        >
                          <b-form-input
                            id="step"
                            v-model="step.label"
                            type="text"
                            placeholder="Passo"
                            required
                          />
                        </b-form-group>
                        <div
                          class="button primary xs d-flex align-items-center justify-content-center"
                          :class="{disabled: result.steps.filter(el => el.label == null || el.label == '' || el.label == undefined).length > 0}"
                          style="margin-bottom: 1rem; margin-left: 15px"
                          @click="() => {
                            if(result.steps.filter(el => el.label == null || el.label == '' || el.label == undefined).length == 0) {
                              toggleShowPoints(step)
                            }
                          }"
                        >
                          <b-icon
                            scale="1.5"
                            icon="list-ul"
                          />
                        </div>
                        <div
                          class="button danger xs d-flex align-items-center justify-content-center"
                          style="margin-bottom: 1rem; margin-left: 15px"
                          @click="() => {
                            showDeleteStepModal(step.id, i)
                          }"
                        >
                          <b-icon
                            scale="1.5"
                            icon="x"
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          class="button primary sm"
                          :class="{disabled: result.steps.length >= result.number_of_steps}"
                          variant="primary"
                          @click="() => {
                            if (result.steps.length < result.number_of_steps) {
                              addStep()
                            }
                          }"
                        >
                          <b-icon
                            icon="plus"
                          /> Adicionar passo
                        </button>
                      </div>
                    </div>
                    <!-- <div
                      style="margin-left: 15px; margin-bottom: 15px"
                      class="d-flex flex-column"
                    > -->
                    <!-- <label style="font-size: 16px; font-weight: 600">Recursos</label>
                      <div
                        v-for="(resource, i) in result.resources.filter(el => el.id > 0)"
                        :key="i"
                        class="d-flex mb-4"
                      >
                        <div
                          v-if="resource.document"
                          class="d-flex flex-row align-items-center justify-content-center"
                        >
                          {{ resource.document.split('DOC-')[1] }}
                          <div
                            class="button primary xs align-items-center justify-content-center ml-2"
                            @click="download(resource.id)"
                          >
                            <b-icon
                              scale="1.2"
                              icon="download"
                            />
                          </div>
                          <div
                            v-if="resource.document"
                            class="button danger xs align-items-center justify-content-center ml-2"
                            @click="() => {
                              showDeleteResourceModal(resource.id, i)
                            }"
                          >
                            <b-icon
                              scale="1.5"
                              icon="x"
                            />
                          </div>
                        </div>
                        <div
                          v-else-if="resource.link"
                          class="d-flex flex-row align-items-center justify-content-center"
                        >
                          {{ resource.link }}
                          <div
                            class="button primary xs align-items-center justify-content-center ml-2"
                            @click="() => {
                              open(resource.link, '_blank');
                            }"
                          >
                            <b-icon
                              scale="1.2"
                              icon="eye"
                            />
                          </div>
                          <div
                            class="button danger xs align-items-center justify-content-center ml-2"
                            @click="() => {
                              showDeleteResourceModal(resource.id, i)
                            }"
                          >
                            <b-icon
                              scale="1.5"
                              icon="x"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="(resource, i) in result.resources.filter(el => el.id < 0)"
                        :key="i"
                        class="d-flex flex-column results-resource"
                      >
                        <div
                          class="d-flex flex-row align-items-end"
                        >
                          <b-form-group
                            id="step"
                            style="flex: 1"
                            :label="i === 0 ? 'Nome *' : ''"
                            label-for="resource"
                          >
                            <b-form-input
                              id="resource"
                              v-model="resource.label"
                              type="text"
                              placeholder="Recurso"
                              required
                            />
                          </b-form-group>
                          <div
                            class="button danger xs d-flex align-items-center justify-content-center"
                            style="margin-bottom: 1rem; margin-left: 15px"
                            @click="() => {
                              showDeleteResourceModal(resource.id, i)
                            }"
                          >
                            <b-icon
                              scale="1.5"
                              icon="x"
                            />
                          </div>
                        </div>
                        <b-form-group
                          label="Documento"
                          label-for="document"
                        >
                          <b-form-file
                            id="document"
                            v-model="resource.document"
                            :name="resource.document ? resource.document.name : ''"
                            placeholder="Selecione um ficheiro ou mova-o para aqui..."
                            drop-placeholder="Largue o ficheiro aqui..."
                          />
                        </b-form-group>
                        <b-form-group
                          id="link"
                          label="Link"
                          label-for="link"
                        >
                          <b-form-input
                            id="link"
                            v-model="resource.link"
                            type="text"
                            placeholder="Link"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Thumbnail"
                          label-for="thumbnail"
                        >
                          <b-form-file
                            id="thumbnail"
                            v-model="resource.thumbnail"
                            required
                            :state="resource.thumbnail ? true : null"
                            :name="resource.thumbnail ? resource.thumbnail.name : ''"
                            placeholder="Selecione um ficheiro ou mova-o para aqui..."
                            drop-placeholder="Largue o ficheiro aqui..."
                            accept=".jpg, .png, .svg"
                          />
                        </b-form-group>
                      </div> -->
                    <!-- <div>
                        <button
                          class="button primary sm"
                          variant="primary"
                          @click="() => {
                            addResource()
                          }"
                        >
                          <b-icon
                            icon="plus"
                          /> Adicionar recurso
                        </button>
                      </div> -->
                    <!-- </div> -->
                    <div>* Campo de preenchimento obrigatório</div>
                    <div class="buttons justify-content-start">
                      <button
                        class="button primary sm"
                        :class="{disabled: !canSaveRoadmap}"
                        type="submit"
                        :disabled="!canSaveRoadmap"
                        variant="primary"
                      >
                        <b-icon icon="cloud-upload" /> Guardar
                      </button>
                      <button
                        class="button warning sm"
                        variant="danger"
                        @click="() => {
                          editResult = false
                          editResultId = null
                        }"
                      >
                        <b-icon
                          icon="x"
                        /> Cancelar
                      </button>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageResults"
          :total-rows="rowsResults"
          :per-page="perPageResults"
          aria-controls="results"
        />
      </b-col>
    </b-row>
    <b-modal
      id="stepPoints"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Pontos"
      hide-footer
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      @show="stepPointsShownHandler"
    >
      <b-nav pills>
        <b-nav-item
          v-for="_dimension in dimensions"
          :key="_dimension.id"
          :active="dimension === _dimension.id ? true : false"
          @click="() => updateDimension(_dimension.id)"
        >
          {{ _dimension.name }}
        </b-nav-item>
      </b-nav>
      <div v-if="result && result.steps && result.steps.find(el => el.id === step)">
        <div class="title">
          Pontos
        </div>
        <div
          v-if="parent.length > 0"
          class="buttons"
        >
          <div
            class="button secondary xs d-flex align-items-center justify-content-center"
            :class="{disabled: result.steps.find(el => el.id === step).points.filter(el => el.text == null || el.text == '' || el.text == undefined).length > 0}"
            style=" margin-right: 15px"
            @click="() => {
              if (result.steps.find(el => el.id === step).points.filter(el => el.text == null || el.text == '' || el.text == undefined).length == 0) {
                parent.pop();
              }
            }"
          >
            <b-icon
              scale="1.5"
              icon="arrow-left"
            />
          </div>
        </div>
        <div
          class="d-flex flex-row align-items-start"
          style="gap: 12px"
        >
          <b-form-group
            style="flex: 3"
            label="Ponto *"
          />
          <!--       <b-form-group
            style="flex: 1"
            label="Ordem *"
          /> -->
          <div
            class="button danger xs d-flex align-items-center justify-content-center"
            style="margin-bottom: 1rem; background-color: transparent; cursor: default"
          />
          <div
            class="button danger xs d-flex align-items-center justify-content-center"
            style="margin-bottom: 1rem; background-color: transparent; cursor: default"
          />
        </div>
        <div
          v-for="(point, i) in result.steps.find(el => el.id === step).points"
          v-if="(point.dimension_id === dimension || point.dimension_id == null) && point.parent_id == parent[parent.length - 1]"
          :key="i"
          class="d-flex flex-row align-items-start"
          style="gap: 12px"
        >
          <b-form-group
            id="point"
            style="flex: 3"
            label-for="point"
          >
            <b-form-textarea
              id="point"
              v-model="result.steps.find(el => el.id === point.roadmap_step_id).points[i].text"
              rows="3"
              placeholder=""
              required
            />
          </b-form-group>
          <!--   <b-form-group
            id="order"
            style="flex: 1"
            label-for="order"
          >
            <b-form-input
              id="order"
              v-model="result.steps.find(el => el.id === point.roadmap_step_id).points[i].order"
              type="number"
              required
            />
          </b-form-group> -->
          <div
            class="button primary xs d-flex align-items-center justify-content-center"
            style="margin-bottom: 1rem;"
            :class="{
              disabled: result.steps.find(el => el.id === point.roadmap_step_id).points.filter(el => el.text == null || el.text == '' || el.text == undefined).length > 0
            }"
            @click="() => {
              if( result.steps.find(el => el.id === point.roadmap_step_id).points.filter(el => el.text == null || el.text == '' || el.text == undefined).length == 0) {
                parent.push(point.id)
              }
            }"
          >
            <b-icon
              scale="1.5"
              icon="list"
            />
          </div>
          <div
            class="button danger xs d-flex align-items-center justify-content-center"
            style="margin-bottom: 1rem;"
            @click="() => {
              showDeletePointModal(point)
            }"
          >
            <b-icon
              scale="1.5"
              icon="x"
            />
          </div>
        </div>
        <div>
          <button
            class="button primary sm"
            variant="primary"
            @click="addPoint"
          >
            <b-icon
              icon="plus"
            /> Adicionar ponto
          </button>
        </div>

        <div class="buttons justify-content-end">
          <button
            class="button secondary sm"

            type="submit"
            @click="() => {
              showCancelChangesModal()
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button primary sm"
            :class="{
              disabled: result.steps.find(el => el.id === step).points.filter(el => el.text == null || el.text == '' || el.text == undefined).length > 0
            }"
            type="submit"
            @click="() => {
              if(result.steps.find(el => el.id === step).points.filter(el => el.text == null || el.text == '' || el.text == undefined).length == 0) {
                points = null
                $bvModal.hide('stepPoints');
              }
            }"
          >
            <b-icon icon="check" />
            Concluir
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import mime from 'mime';
import {
  get, post, postFile, put, remove,
} from '../../../services/api';

export default {
  name: 'RoadmapsTab',
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      currentPageResults: 1,
      filterOnResults: ['label'],
      perPageResults: 10,
      results: null,
      deleteResultId: null,
      filterResults: null,
      deletedPoints: [],
      deletedSteps: [],
      deletedResources: [],
      parent: [],
      tempStep: null,
      fieldsResults: [
        {
          key: 'label', sortable: true, label: 'Nome', thStyle: { width: '30%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      createNewResult: false,
      editResult: false,
      editResultId: null,
      step: null,
      result: null,
      newResult: {
        label: '',
        active: true,
        component: null,
        content: null,
      },
      dimension: null,
      dimensions: [],
    };
  },
  computed: {
    canSaveRoadmap() {
      return this.result.steps.filter((el) => el.label == null
        || el.label == ''
        || el.label == undefined).length === 0
      && this.result.steps.length === this.result.number_of_steps;
    },
    rowsResults() {
      if (this.results) {
        return this.results.length;
      }
      return 0;
    },
  },

  async created() {
    this.setLoading(true);
    await this.getResults();
    if (this.dimensions && this.dimensions.length > 0) {
      this.dimension = this.dimensions[0].id;
    }
    this.setLoading(false);
  },
  methods: {
    open(link) {
      window.open(link, '_blank');
    },
    async download(id) {
      this.setLoading(true);
      await get(`results/roadmaps/resources/${id}`).then((response) => {
        const linkSource = `data:${mime.getType(response.data.document_path)};base64,${response.data.document}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.document_path;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
      this.setLoading(false);
    },
    stepPointsShownHandler() {
      this.tempStep = { ...this.result.steps.find((el) => el.id === this.step) };
    },
    stepPointsHiddenHandler() {
      const index = this.result.steps.findIndex((el) => el.id === this.step);
      this.result.steps[index] = this.tempStep;
      this.tempStep = null;
    },
    showCancelChangesModal() {
      this.$bvModal.msgBoxConfirm('Tem a certeza que pretende descartar as últimas alterações?',
        {
          okTitle: 'Sim',
          cancelTitle: 'Não',
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.points = null;
            this.$bvModal.hide('stepPoints');
            this.stepPointsHiddenHandler();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    showDeleteStepModal(stepId, index) {
      this.$bvModal.msgBoxConfirm('Tem a certeza que pretende eliminar este passo?',
        {
          okTitle: 'Sim',
          cancelTitle: 'Não',
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletedSteps.push(stepId);
            this.result.steps = this.result.steps.filter((el, j) => index !== j);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    showDeleteResourceModal(resourceId, index) {
      this.$bvModal.msgBoxConfirm('Tem a certeza que pretende eliminar este recurso?',
        {
          okTitle: 'Sim',
          cancelTitle: 'Não',
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletedResources.push(resourceId);
            this.result.resources = this.result.resources.filter((el, j) => index !== j);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    showDeletePointModal(point) {
      this.$bvModal.msgBoxConfirm('Tem a certeza que pretende eliminar este ponto?',
        {
          okTitle: 'Sim',
          cancelTitle: 'Não',
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletedPoints.push(point.id);
            this.deletePoint(point);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deletePoint(point) {
      const childs = this.result.steps.find((el) => el.id === point.roadmap_step_id).points.filter((el) => el.parent_id === point.id);

      childs.forEach((child) => {
        this.deletePoint(child);
      });

      this.result.steps.find((el) => el.id === point.roadmap_step_id).points = this.result.steps.find((el) => el.id === point.roadmap_step_id).points.filter((el) => el.id !== point.id);
    },
    addStep() {
      let minId = 0;
      this.result.steps.forEach((step) => {
        if (step.id < minId) {
          minId = step.id;
        }
      });

      this.result.steps = [
        ...this.result.steps,
        {
          id: minId - 1,
          label: '',
          title: '',
          roadmap_id: this.result.id,
          order: this.result.steps.length,
          points: [],
        },
      ];
    },
    addResource() {
      let minId = 0;
      this.result.resources.forEach((resource) => {
        if (resource.id < minId) {
          minId = resource.id;
        }
      });

      this.result.resources = [
        ...this.result.resources,
        {
          id: minId - 1,
          label: '',
          link: null,
          document: null,
          thumbnail: null,
          roadmap_id: this.result.id,
        },
      ];
    },
    addPoint() {
      let minId = 0;
      this.result.steps.forEach((step) => {
        step.points.forEach((point) => {
          if (point.id < minId) {
            minId = point.id;
          }
        });
      });
      this.result.steps.find((el) => el.id === this.step).points = [
        ...this.result.steps.find((el) => el.id === this.step).points,
        {
          id: minId - 1,
          text: '',
          roadmap_step_id: this.step,
          dimension_id: this.dimension,
          order: this.maxOrder(this.result.steps.find((el) => el.id === this.step).points),
          parent_id: this.parent[this.parent.length - 1] || null,
        },
      ];
    },
    maxOrder(points) {
      let max = 0;
      points.forEach((point) => {
        if (
          point.roadmap_step_id === this.step && point.dimension_id === this.dimension && point.parent_id == this.parent[this.parent.length - 1] && point.order > max
        ) {
          max = point.order;
        }
      });

      return max + 1;
    },
    updateDimension(dimension) {
      this.parent = [];
      this.dimension = dimension;
    },
    toggleShowPoints(step) {
      this.step = step.id;
      this.parent = [];
      this.$bvModal.show('stepPoints');
    },
    setEditResult(id) {
      this.result = this.results.find((el) => el.id === id);

      this.editResultId = id;
      this.editResult = true;
    },
    async getResults() {
      await get('results/roadmaps/', { all: true }).then((response) => {
        this.results = response.data;
        this.results.forEach((roadmap, i) => {
          roadmap.steps.forEach((step, j) => {
            this.results[i].steps[j].points = this.groupPoints(step.points, 0);
          });
        });
      });
      await get('dimension', { all: true }).then((response) => {
        this.dimensions = response.data;
      });
    },

    groupPoints(points) {
      const finalPoints = [];
      points.forEach((point) => {
        finalPoints.push({ ...point });
        if (point.childs && point.childs.length > 0) {
          const _tempPoints = this.groupPoints(point.childs);
          _tempPoints.forEach((_tempPoint) => {
            finalPoints.push({ ..._tempPoint });
          });
        }
      });

      return finalPoints;
    },
    async asyncForEach(array, callback) {
      /* eslint-disable no-await-in-loop */
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array);
      }
      /* eslint-enable no-await-in-loop */
    },
    async resultSave(event) {
      event.preventDefault();
      this.setLoading(true);

      await put(`results/roadmaps/${this.editResultId}`, {
        roadmap: this.result,
        deletedPoints: this.deletedPoints,
        deletedSteps: this.deletedSteps,
        deletedResources: this.deletedResources,
      }).then(() => {
        this.deletedPoints = [];
        this.deletedSteps = [];
        this.deletedResources = [];
      });

      await this.asyncForEach(this.result.resources.filter((el) => el.id < 0), async (resource) => {
        const formData = new FormData();
        if (resource.document) {
          formData.append('files[]', resource.document, `DOC-${resource.document.name}`);
        }
        if (resource.thumbnail) {
          formData.append('files[]', resource.thumbnail, `THUMB-${resource.thumbnail.name}`);
        }
        formData.append('attributes', JSON.stringify(
          {
            roadmap_id: resource.roadmap_id,
            label: resource.label,
            link: resource.link,
            id: resource.id,
          },
        ));

        await postFile('results/roadmaps/resources', formData);
      });

      this.editResult = false;
      this.editResultId = null;
      await this.getResults();
      this.setLoading(false);
    },
    async newResultSave(event) {
      event.preventDefault();

      await post('results/', this.newResult);
      this.createNewResult = false;
      this.newResult = null;
      this.getResults();
    },
  },
};
</script>
