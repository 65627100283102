<template>
  <div>
    <!--  <b-row>
      <b-col cols="12">
        <div class="buttons justify-content-start mt-2">
          <div
            class="button primary sm"

            @click="() => {
              if(createNewResult) {
                createNewResult = false
                newResult = {
                  label: '',
                  active: true,
                  component: null ,
                  content: null,
                }
              } else {
                createNewResult = true
              }
            }"
          >
            <b-icon icon="plus" /> Adicionar Resultado
          </div>
        </div>
      </b-col>
      <b-col v-if="createNewResult">
        <b-form
          style="text-align: left;"
          @submit="newResultSave"
        >
          <b-form-group
            id="label"
            label="Nome *"
            label-for="label"
          >
            <b-form-input
              id="label"
              v-model="newResult.label"
              type="text"
              placeholder="Nome do Resultado"
              required
            />
          </b-form-group>

          <div>* Campo de preenchimento obrigatório</div>
          <div class="buttons justify-content-start">
            <button
              class="button primary sm"
              type="submit"
              variant="primary"
            >
              <b-icon
                icon="cloud-upload"
              /> Guardar
            </button>
            <button
              class="button warning sm"
              variant="danger"
              @click="() => {
                createNewResult = false
                newResult = {
                  label: '',
                  active: true,
                  component: null ,
                  content: null,
                }
              }"
            >
              <b-icon
                icon="x"
              /> Cancelar
            </button>
          </div>
        </b-form>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterResults"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterResults"
              @click="filterResults = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          v-if="results"
          id="results"
          :items="results"
          :per-page="perPageResults"
          :current-page="currentPageResults"
          :fields="fieldsResults"
          :filter="filterResults"
          :filter-included-fields="filterOnResults"
          borderless
          hover
          small
        >
          <template
            #cell(selected)="{ rowSelected }"
          >
            <template
              v-if="rowSelected"
            >
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="rowSelected"
              />
            </template>
          </template>
          <!-- <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template> -->
          <template #row-details="row">
            <b-card>
              <div style=" align-items: flex-start; display: flex; flex-direction: column;">
                <div
                  v-if="!editResult || editResultId !== row.item['id'] "
                  style=" align-items: flex-start; display: flex; flex-direction: column;"
                >
                  <div
                    style="order: 1;"
                  >
                    <b>Nome:</b> {{ row.item['label'] }}
                  </div>
                  <div
                    v-if=" row.item['created_at']"
                    style="order: 8;"
                  >
                    <b>Criado em:</b> {{ row.item['created_at'] }}
                  </div>
                  <!--  <div
                    class="buttons"
                    style="order: 9;"
                  >
                    <div
                      class="button primary sm"
                      @click="setEditResult(row.item['id'])"
                    >
                      <b-icon icon="pencil" /> Editar
                    </div>
                    <div
                      v-if="row.item.active"
                      class="button danger sm"
                      @click="() => {
                        deleteResultId = row.item['id'];
                        $bvModal.show('deleteResult')
                      }"
                    >
                      <b-icon icon="trash" /> Desativar
                    </div>
                    <div
                      v-else
                      class="button success sm"
                      @click="() => {
                        deleteResultId = row.item['id'];
                        $bvModal.show('activeResult')
                      }"
                    >
                      <b-icon icon="check" /> Ativar
                    </div>
                  </div> -->
                </div>
                <div
                  v-else
                  style="width: 100%;"
                >
                  <b-form
                    v-if="result && editResult && editResultId === row.item['id']"
                    style="text-align: left;"
                    @submit="resultSave"
                  >
                    <b-form-group
                      id="label"
                      label="Nome *"
                      label-for="label"
                    >
                      <b-form-input
                        id="label"
                        v-model="result.label"
                        type="text"
                        placeholder="Nome do Resultado"
                        required
                      />
                    </b-form-group>

                    <div>* Campo de preenchimento obrigatório</div>
                    <div class="buttons justify-content-start">
                      <button
                        class="button primary sm"
                        type="submit"
                        variant="primary"
                      >
                        <b-icon icon="cloud-upload" /> Guardar
                      </button>
                      <button
                        class="button warning sm"
                        variant="danger"
                        @click="() => {
                          editResult = false
                          editResultId = null
                        }"
                      >
                        <b-icon
                          icon="x"
                        /> Cancelar
                      </button>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageResults"
          :total-rows="rowsResults"
          :per-page="perPageResults"
          aria-controls="results"
        />
      </b-col>
    </b-row>
    <b-modal
      id="deleteResult"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="desativar resultado"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Desativar Resultado
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende desativar este resultado?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteResultId = null
              $bvModal.hide('deleteResult');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button danger sm"
            type="submit"
            @click="deleteResult"
          >
            <b-icon icon="trash" />
            Desativar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="activeResult"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="ativar resultado"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Ativar Resultado
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende ativar este resultado?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteResultId = null
              $bvModal.hide('activeResult');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button success sm"
            type="submit"
            @click="activeResult"
          >
            <b-icon icon="check" />
            Ativar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  get, post, put, remove,
} from '../../../services/api';

export default {
  name: 'ResultsTab',
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      currentPageResults: 1,
      filterOnResults: ['label'],
      perPageResults: 10,
      results: null,
      deleteResultId: null,
      filterResults: null,
      fieldsResults: [
        {
          key: 'label', sortable: true, label: 'Nome', thStyle: { width: '30%' },
        },
        {
          key: 'active',
          sortable: true,
          label: 'Ativo',
          formatter: (value) => (value ? 'Sim' : 'Não'),
          thStyle: { width: '10%' },
        },
        // { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      createNewResult: false,
      editResult: false,
      editResultId: null,
      newResult: {
        label: '',
        active: true,
        component: null,
        content: null,
      },
    };
  },
  computed: {
    rowsResults() {
      if (this.results) {
        return this.results.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getResults();
    this.setLoading(false);
  },
  methods: {
    setEditResult(id) {
      this.result = this.results.find((el) => el.id === id);

      this.editResultId = id;
      this.editResult = true;
    },
    async getResults() {
      await get('results/', { all: true }).then((response) => {
        this.results = response.data;
      });
    },

    async activeResult() {
      await put(`results/${this.deleteResultId}`, {
        active: 1,
      });
      this.deleteResultId = null;
      this.getResults();
      this.$bvModal.hide('activeResult');
    },
    async deleteResult() {
      await put(`results/${this.deleteResultId}`, {
        active: 0,
      });
      this.deleteResultId = null;
      this.getResults();
      this.$bvModal.hide('deleteResult');
    },
    async resultSave(event) {
      event.preventDefault();

      await put(`results/${this.editResultId}`, this.result);
      this.editResult = false;
      this.editResultId = null;
      this.getResults();
    },
    async newResultSave(event) {
      event.preventDefault();

      await post('results/', this.newResult);
      this.createNewResult = false;
      this.newResult = null;
      this.getResults();
    },
  },
};
</script>
