<template>
  <div>
    <div class="buttons justify-content-start mt-2">
      <download-excel
        class="button primary sm"
        :data="preRegisters"
        :fields="fieldsExport"
        name="Manifestações de interesse"
      >
        <b-icon
          class="mr-2"
          scale="1"
          icon="file-earmark-excel-fill"
        />Exportar para excel
      </download-excel>
    </div>
    <b-input-group
      size="sm"
      class="mb-4"
    >
      <template #prepend>
        <b-input-group-text>
          <b-icon
            icon="search"
            scale="0.8"
          />
        </b-input-group-text>
      </template>
      <b-form-input
        id="filter-input"
        v-model="filterPreRegisters"
        type="search"
        placeholder="Pesquisar..."
      />

      <b-input-group-append>
        <b-button
          :disabled="!filterPreRegisters"
          @click="filterPreRegisters = ''"
        >
          Limpar
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-table
      v-if="preRegisters"
      id="pre_registers"
      class="pre_registers"
      responsive
      :items="preRegisters"
      :per-page="perPagePreRegisters"
      :current-page="currentPagePreRegisters"
      :fields="fieldsPreRegisters"
      :filter="filterPreRegisters"
      :filter-included-fields="filterOnPreRegisters"
      borderless
      hover
      small
    >
      <template #head()="data">
        <div
          v-b-popover.hover.left.v-dark="data.label"
        >
          {{ data.label }}
        </div>
      </template>
      <template
        #cell()="data"
      >
        <div
          v-b-popover.hover.left.v-dark="typeof data.field.formatter == 'function' ? data.field.formatter(data.item[data.field.key]) : data.item[data.field.key]"
        >
          {{ typeof data.field.formatter == 'function' ? data.field.formatter(data.item[data.field.key]) : data.item[data.field.key] }}
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPagePreRegisters"
      :total-rows="rowsRegisters"
      :per-page="perPagePreRegisters"
      aria-controls="pre_registers"
    />
  </div>
</template>

<script>
import { get } from '../../../services/api';

export default {
  name: 'PreRegistersTab',
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      regions: {
        'R-1': 'Alto Minho',
        'R-2': 'Cávado',
        'R-3': 'Ave',
        'R-4': 'Área Metropolitana do Porto',
        'R-5': 'Alto Tâmega',
        'R-6': 'Tâmega e Sousa',
        'R-7': 'Douro',
        'R-8': 'Terras de Trás-os-Montes',
        'R-9': 'Oeste',
        'R-10': 'Região de Aveiro',
        'R-11': 'Região de Coimbra',
        'R-12': 'Região de Leiria',
        'R-13': 'Viseu Dão Lafões',
        'R-14': 'Beira Baixa',
        'R-15': 'Médio Tejo',
        'R-16': 'Beiras e Serra da Estrela',
        'R-17': 'Área Metroplotinada de Lisboa',
        'R-18': 'Alentejo Litoral',
        'R-19': 'Baixo Alentejo',
        'R-20': 'Lezíria do Tejo',
        'R-21': 'Alto Alentejo',
        'R-22': 'Alentejo Central',
        'R-23': 'Algarve',
        'R-24': 'Região Autónoma dos Açores',
        'R-25': 'Região Autónoma da Madeira',
      },
      choices: [
        { text: 'Agricultura, produção animal, caça e actividades dos serviços relacionados', value: 1 },
        { text: 'Silvicultura e exploração florestal', value: 2 },
        { text: 'Pesca e aquicultura', value: 3 },
        { text: 'Extracção de hulha e lenhite', value: 5 },
        { text: 'Extracção de petróleo bruto e gás natural', value: 6 },
        { text: 'Extracção e preparação de minérios metálicos', value: 7 },
        { text: 'Outras indústrias extractivas', value: 8 },
        { text: 'Actividades dos serviços relacionados com as indústrias extractivas', value: 9 },
        { text: 'Indústrias alimentares', value: 10 },
        { text: 'Indústria das bebidas', value: 11 },
        { text: 'Indústria do tabaco', value: 12 },
        { text: 'Fabricação de têxteis', value: 13 },
        { text: 'Indústria do vestuário', value: 14 },
        { text: 'Indústria do couro e dos produtos do couro', value: 15 },
        { text: 'Indústrias da madeira e da cortiça e suas obras, excepto mobiliário; fabricação de obras de cestaria e de espartaria', value: 16 },
        { text: 'Fabricação de pasta, de papel, cartão e seus artigos', value: 17 },
        { text: 'Impressão e reprodução de suportes gravados', value: 18 },
        { text: 'Fabricação de coque, de produtos petrolíferos refinados e de aglomerados de combustíveis', value: 19 },
        { text: 'Fabricação de produtos químicos e de fibras sintéticas ou artificiais, excepto produtos farmacêuticos', value: 20 },
        { text: 'Fabricação de produtos farmacêuticos de base e de preparações farmacêuticas', value: 21 },
        { text: 'Fabricação de artigos de borracha e de matérias plásticas', value: 22 },
        { text: 'Fabricação de outros produtos minerais não metálicos', value: 23 },
        { text: 'Indústrias metalúrgicas de base', value: 24 },
        { text: 'Fabricação de produtos metálicos, excepto máquinas e equipamentos', value: 25 },
        { text: 'Fabricação de equipamentos informáticos, equipamento para comunicações e produtos electrónicos e ópticos', value: 26 },
        { text: 'Fabricação de equipamento eléctrico', value: 27 },
        { text: 'Fabricação de máquinas e de equipamentos, n.e.', value: 28 },
        { text: 'Fabricação de veículos automóveis, reboques, semi-reboques e componentes para veículos automóveis', value: 29 },
        { text: 'Fabricação de outro equipamento de transporte', value: 30 },
        { text: 'Fabricação de mobiliário e de colchões', value: 31 },
        { text: 'Outras indústrias transformadoras', value: 32 },
        { text: 'Reparação, manutenção e instalação de máquinas e equipamentos', value: 33 },
        { text: 'Electricidade, gás, vapor, água quente e fria e ar frio', value: 35 },
        { text: 'Captação, tratamento e distribuição de água', value: 36 },
        { text: 'Recolha, drenagem e tratamento de águas residuais', value: 37 },
        { text: 'Recolha, tratamento e eliminação de resíduos; valorização de materiais', value: 38 },
        { text: 'Descontaminação e actividades similares', value: 39 },
        { text: 'Promoção imobiliária (desenvolvimento de projectos de edifícios); construção de edifícios', value: 41 },
        { text: 'Engenharia civil', value: 42 },
        { text: 'Actividades especializadas de construção', value: 43 },
        { text: 'Comércio, manutenção e reparação, de veículos automóveis e motociclos', value: 45 },
        { text: 'Comércio por grosso (inclui agentes), excepto de veículos automóveis e motociclos', value: 46 },
        { text: 'Comércio a retalho, excepto de veículos automóveis e motociclos', value: 47 },
        { text: 'Transportes terrestres e transportes por oleodutos ou gasodutos', value: 49 },
        { text: 'Transportes por água', value: 50 },
        { text: 'Transportes aéreos', value: 51 },
        { text: 'Armazenagem e actividades auxiliares dos transportes(inclui manuseamento)', value: 52 },
        { text: 'Actividades postais e de courier', value: 53 },
        { text: 'Alojamento', value: 55 },
        { text: 'Restauração e similares', value: 56 },
        { text: 'Actividades de edição', value: 58 },
        { text: 'Actividades cinematográficas, de vídeo, de produção de programas de televisão, de gravação de som e de edição de música', value: 59 },
        { text: 'Actividades de rádio e de televisão', value: 60 },
        { text: 'Telecomunicações', value: 61 },
        { text: 'Consultoria e programação informática e actividades relacionadas', value: 62 },
        { text: 'Actividades dos serviços de informação', value: 63 },
        { text: 'Actividades de serviços financeiros, excepto seguros e fundos de pensões', value: 64 },
        { text: 'Seguros, resseguros e fundos de pensões, excepto segurança social obrigatória', value: 65 },
        { text: 'Actividades auxiliares de serviços financeiros e dos seguros', value: 66 },
        { text: 'Actividades imobiliárias', value: 68 },
        { text: 'Actividades jurídicas e de contabilidade', value: 69 },
        { text: 'Actividades das sedes sociais e de consultoria para a gestão', value: 70 },
        { text: 'Actividades de arquitectura, de engenharia e técnicas afins; actividades de ensaios e de análises técnicas', value: 71 },
        { text: 'Actividades de Investigação científica e de desenvolvimento', value: 72 },
        { text: 'Publicidade, estudos de mercado e sondagens de opinião', value: 73 },
        { text: 'Outras actividades de consultoria, científicas, técnicas e similares', value: 74 },
        { text: 'Actividades veterinárias', value: 75 },
        { text: 'Actividades de aluguer', value: 77 },
        { text: 'Actividades de emprego', value: 78 },
        { text: 'Agências de viagem, operadores turísticos, outros serviços de reservas e actividades relacionadas', value: 79 },
        { text: 'Actividades de investigação e segurança', value: 80 },
        { text: 'Actividades relacionadas com edifícios, plantação e manutenção de jardins', value: 81 },
        { text: 'Actividades de serviços administrativos e de apoio prestados às empresas', value: 82 },
        { text: 'Administração Pública e Defesa; Segurança Social Obrigatória', value: 84 },
        { text: 'Educação', value: 85 },
        { text: 'Actividades de saúde humana', value: 86 },
        { text: 'Actividades de apoio social com alojamento', value: 87 },
        { text: 'Actividades de apoio social sem alojamento', value: 88 },
        { text: 'Actividades de teatro, de música, de dança e outras actividades artísticas e literárias', value: 90 },
        { text: 'Actividades das bibliotecas, arquivos, museus e outras actividades culturais', value: 91 },
        { text: 'Lotarias e outros jogos de aposta', value: 92 },
        { text: 'Actividades desportivas, de diversão e recreativas', value: 93 },
        { text: 'Actividades das organizações associativas', value: 94 },
        { text: 'Reparação de computadores e de bens de uso pessoal e doméstico', value: 95 },
        { text: 'Outras actividades de serviços pessoais', value: 96 },
        { text: 'Actividades das famílias empregadoras de pessoal doméstico', value: 97 },
        { text: 'Actividades de produção de bens e serviços pelas famílias para uso próprio', value: 98 },
        { text: 'Actividades dos organismos internacionais e outras instituições extraterritoriais', value: 99 },
      ],
      interests: [
        { value: 'diagnostic', text: 'Realizar diagnóstico assistido de maturidade digital da empresa' },
        { value: 'sessions', text: 'Sessões de Informação e capacitação para apoio à transição digital da empresa' },
        { value: 'share_good_practices', text: 'Estar envolvido em iniciativas de partilha de boas práticas e acesso a ferramentas i4.0' },
        { value: 'receive_info', text: 'Receber informação sobre estratégias i4.0 dedicadas ao setor de atividade da empresa' },
      ],
      filterPreRegisters: null,
      filterOnPreRegisters: [],
      perPagePreRegisters: 10,
      currentPagePreRegisters: 1,
      fieldsPreRegisters: [
        {
          key: 'name',
          sortable: true,
          label: 'Nome',
          tdClass: 'table--fields-sm',
          thClass: 'table--fields-sm',
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Submetido em',
          formatter: (value) => {
            const date = new Date(value);

            return [
              date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate(),
              (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
          sortByFormatted: true,
          filterByFormatted: true,
          thClass: 'table--fields-sm',
          tdClass: 'table--fields-sm',
        },
        {
          key: 'email',
          sortable: true,
          label: 'Email',
          thClass: 'table--fields-sm',
          tdClass: 'table--fields-sm',
        },
        {
          key: 'company',
          sortable: true,
          label: 'Empresa',
          thClass: 'table--fields-sm',
          tdClass: 'table--fields-sm',
        },
        {
          key: 'employees',
          sortable: true,
          label: 'Nº de empregados',
          thClass: 'table--fields-xs',
          tdClass: 'table--fields-xs',
        },
        {
          key: 'phone',
          sortable: true,
          label: 'Nº de telemóvel',
          thClass: 'table--fields-sm',
          tdClass: 'table--fields-sm',
        },
        {
          key: 'region',
          sortable: true,
          label: 'Região',
          formatter: (value) => this.regions[value],
          sortByFormatted: true,
          filterByFormatted: true,
          thClass: 'table--fields-sm',
          tdClass: 'table--fields-sm',
        },
        {
          key: 'activity_sector',
          sortable: true,
          label: 'Setor',
          formatter: (value) => this.choices.find((el) => el.value == value).text,
          sortByFormatted: true,
          filterByFormatted: true,
          thClass: 'table--fields-sm',
          tdClass: 'table--fields-sm',
        },
      ],
      fieldsExport: {
        Nome: 'name',
        Empresa: 'company',
        'Nº de telemóvel': 'phone',
        Email: 'email',
        Data: {
          field: 'created_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate(),
              (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        Hora: {
          field: 'created_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getHours().toString().length < 2 ? `0${date.getHours()}` : date.getHours(),
              date.getMinutes().toString().length < 2 ? `0${date.getMinutes()}` : date.getMinutes(),
            ].join(':');
          },
        },
        Região: {
          field: 'region',
          callback: (value) => this.regions[value],
        },
        Empregados: 'employees',
        Setor: {
          field: 'activity_sector',
          callback: (value) => this.choices.find((el) => el.value == value).text,
        },
        'Realizar diagnostico': {
          field: 'interests',
          callback: (value) => (value.includes('diagnostic') ? 'Sim' : 'Não'),
        },
        'Sessões de informação': {
          field: 'interests',
          callback: (value) => (value.includes('sessions') ? 'Sim' : 'Não'),
        },
        'Envolvimento em iniciativas': {
          field: 'interests',
          callback: (value) => (value.includes('share_good_practices') ? 'Sim' : 'Não'),
        },
        'Receber informação': {
          field: 'interests',
          callback: (value) => (value.includes('receive_info') ? 'Sim' : 'Não'),
        },
      },
      preRegisters: null,

    };
  },
  computed: {
    rowsRegisters() {
      if (this.preRegisters) {
        return this.preRegisters.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getPreRegisters();
    this.setLoading(false);
  },
  methods: {
    async getPreRegisters() {
      await get('pre-register/').then((response) => {
        this.preRegisters = response.data;
      });
    },
  },
};
</script>
