<template>
  <div>
    <b-row>
      <b-col class="title mb-4">
        Lista de facilitadores por aprovar
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-4"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterInApproving"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterInApproving"
              @click="filterInApproving = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          id="inApproving"
          :items="facilitators && facilitators.filter(el => el.state == 1)"
          per-page="10"
          :current-page="currentPageInApproving"
          :fields="fieldsInApproving"
          :filter="filterInApproving"
          :filter-included-fields="filterOnInApproving"
          borderless
          hover
          small
          show-empty
          empty-filtered-text="Sem pedidos por aprovar"
          empty-text="Sem pedidos por aprovar"
        >
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button danger xs align-items-center justify-content-center"
                @click="() => {
                  rejectId = row.item.id
                  $bvModal.show('reject');
                }"
              >
                <b-icon
                  scale="1.2"
                  icon="trash"
                />
              </div>
              <div
                class="button success xs align-items-center justify-content-center"
                @click="() => {
                  approveId = row.item.id
                  $bvModal.show('approve');
                }"
              >
                <b-icon
                  scale="1.2"
                  icon="check"
                />
              </div>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageInApproving"
          :total-rows="facilitators && facilitators.filter(el => el.state == 1).length"
          per-page="10"
          aria-controls="inApproving"
        />
      </b-col>
    </b-row>

    <b-row class="mt-4 mb-4">
      <b-col class="title">
        Lista de facilitadores aprovados
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-4"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterApproved"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterApproved"
              @click="filterApproved = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          id="approved"
          :items="facilitators && facilitators.filter(el => el.state == 2)"
          per-page="10"
          :current-page="currentPageApproved"
          :fields="fieldsApproved"
          :filter="filterApproved"
          :filter-included-fields="filterOnApproved"
          borderless
          hover
          small
          show-empty
          empty-filtered-text="Sem facilitadores aprovados"
          empty-text="Sem facilitadores aprovados"
        >
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button danger xs align-items-center justify-content-center"
                @click="() => {
                  deleteFacilitatorId = row.item.id
                  $bvModal.show('deleteFacilitator');
                }"
              >
                <b-icon
                  scale="1.2"
                  icon="trash"
                />
              </div>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageApproved"
          :total-rows="facilitators && facilitators.filter(el => el.state == 2).length"
          per-page="10"
          aria-controls="approved"
        />
      </b-col>
    </b-row>

    <b-modal
      id="deleteFacilitator"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Remover"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Remover
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende remover este perfil de facilitador?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm align-items-center justify-content-center"
            type="submit"
            @click="() => {
              deleteFacilitatorId = null
              $bvModal.hide('deleteFacilitator');
            }"
          >
            <b-icon
              icon="x"
            />
            Cancelar
          </button>
          <button
            class="button danger sm align-items-center justify-content-center"
            type="submit"
            @click="deleteFacilitator"
          >
            <b-icon
              icon="trash"
            />
            Remover
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="approve"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Aprovar"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Aprovar
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende aprovar este pedido de perfil de facilitador?
        </div>

        <b-form-group>
          <b-form-textarea
            id="textarea"
            v-model="feedback"
            class="mt-4"
            placeholder="Feedback..."
            rows="3"
            max-rows="6"
          />
        </b-form-group>

        <div class="buttons">
          <button
            class="button secondary sm align-items-center justify-content-center"
            type="submit"
            @click="() => {
              approveId = null
              $bvModal.hide('approve');
            }"
          >
            <b-icon
              icon="x"
            />
            Cancelar
          </button>
          <button
            class="button success sm align-items-center justify-content-center"
            type="submit"
            @click="approve"
          >
            <b-icon icon="check" />
            Aprovar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="reject"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="Rejeitar"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Rejeitar
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende rejeitar este pedido de perfil de facilitador?
        </div>

        <b-form-group>
          <b-form-textarea
            id="textarea"
            v-model="feedback"
            class="mt-4"
            placeholder="Feedback..."
            rows="3"
            max-rows="6"
          />
        </b-form-group>

        <div class="buttons">
          <button
            class="button secondary sm align-items-center justify-content-center"
            type="submit"
            @click="() => {
              rejectId = null
              $bvModal.hide('reject');
            }"
          >
            <b-icon
              icon="x"
            />
            Cancelar
          </button>
          <button
            class="button danger sm align-items-center justify-content-center"
            type="submit"
            @click="reject"
          >
            <b-icon
              icon="trash"
            />
            Rejeitar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { get, put, remove } from '../../../services/api';

export default {
  name: 'FacilitatorsTab',
  props: {

    setLoading: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      currentPageInApproving: 1,
      currentPageApproved: 1,
      fieldsInApproving: [
        {
          key: 'user.name', sortable: true, label: 'Nome', thStyle: { width: '45%' },
        },
        {
          key: 'user.email', sortable: true, label: 'Email', thStyle: { width: '45%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      fieldsApproved: [
        {
          key: 'user.name', sortable: true, label: 'Nome', thStyle: { width: '45%' },
        },
        {
          key: 'user.email', sortable: true, label: 'Email', thStyle: { width: '45%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      filterOnInApproving: [],
      filterOnApproved: [],
      filterInApproving: '',
      filterApproved: '',
      facilitators: null,
      existPending: false,
      existFacilitators: false,
      deleteFacilitatorId: null,
      approveId: null,
      rejectId: null,
      feedback: '',
    };
  },

  async created() {
    this.setLoading(true);
    await this.getFacilitators();
    this.setLoading(false);
  },
  methods: {
    async getFacilitators() {
      await get('facilitator/').then((response) => {
        this.facilitators = response.data;
        this.existPending = false;
        this.existFacilitators = false;
      }).catch(() => {
        this.existPending = false;
      });
    },
    async approve() {
      this.setLoading(true);
      this.$bvModal.hide('approve');
      await put(`facilitator/${this.approveId}`, {
        state: 2,
        feedback: this.feedback,
      }).then(async () => {
        this.approveId = null;
        await this.getFacilitators();
        this.$bvModal.hide('approve');
      }).catch(() => {
        this.$bvModal.show('approve');
      });
      this.feedback = '';
      this.setLoading(false);
    },
    async reject() {
      this.setLoading(true);
      this.$bvModal.hide('reject');
      await put(`facilitator/${this.rejectId}`, {
        state: 3,
        feedback: this.feedback,
      }).then(async () => {
        this.rejectId = null;
        await this.getFacilitators();
        this.$bvModal.hide('reject');
      }).catch(() => {
        this.$bvModal.show('reject');
      });
      this.feedback = '';
      this.setLoading(false);
    },
    async deleteFacilitator() {
      await remove(`facilitator/${this.deleteFacilitatorId}`, {})
        .then(async () => {
          this.facilitators = null;
          this.deleteFacilitatorId = null;
          await this.getFacilitators();
          this.$bvModal.hide('deleteFacilitator');
        });
    },
  },
};
</script>
